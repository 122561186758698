import React, { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  DataTable,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../components/Component";
import UserProfileAside from "./PurchaseRequestAside";
import { DoubleBar } from "../../components/partials/charts/default/Charts";
import sites from "../../api/master/sites";
import FormatDate from "../masters/DateFormater";
import PurchaseOrderAside from "../purchase-orders/PurchaseOrderAside";
import { Empty, Flex, Input, Select, Spin, Table } from "antd";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import { ColumnGroupType, ColumnType } from "antd/es/table";
import moment from "moment";
import PurchaseRequestAside from "./PurchaseRequestAside";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
import paymentApis from "../../api/payments/payments";
const { Option } = Select;
interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
}

// const orderStatusCards: any = {
//   // draft: {
//   //   color: "#e85347",
//   //   lable: "Draft Purchase Order",
//   // },

//   pending: {
//     color: "#09c2de",
//     lable: "Pending Approvals",
//   },
//   withSuppliers: {
//     color: "#364a63",
//     lable: "With Suppliers",
//   },
//   invoiced: {
//     color: "#1ee0ac",
//     lable: "Invoiced",
//   },
//   deliveredGrn: {
//     color: "#2263b3",
//     lable: "Delivered GRN",
//   },
//   shortClosed: {
//     color: "rgb(249, 140, 69)",
//     lable: "Short Closed",
//   },
// };

const ViewPurchaseRequests: FC = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [filterStatus, setfilterStatus] = useState<any>([]);
  useEffect(() => {
    fetchStatusFilterOptions();
  }, []);
  const fetchStatusFilterOptions = async () => {
    try {
      const res = await paymentApis.fetchStatusFilterOptions();
      console.log("status");
      const { response, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = response.data;
        console.log("dateresp", dateresp);
        setfilterStatus(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };
  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "PR #",
      dataIndex: "prSequence",
      key: "prSequence",
      fixed: "left",
      width: 200,
      align: "left",
    },
    {
      title: "Indent Number",
      dataIndex: "referenceIndentNumber",
      key: "referenceIndentNumber",
      // fixed: "left",
      width: 200,
      align: "left",
    },
    {
      title: "Indent Date",
      dataIndex: "indentDate",
      key: "indentDate",
      // fixed: "left",
      width: 200,
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
      align: "left",
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Requested By",
      dataIndex: "requestByName",
      key: "requestByName",
      width: 200,
      align: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "lastDateOfSubmission",
      key: "lastDateOfSubmission",
      width: 200,
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Delivery Location",
      dataIndex: "deliveryAddressName",
      key: "deliveryAddressName",
      width: 150,
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Created By",
      dataIndex: "createdUser",
      key: "createdUser",
      width: 150,
      align: "left",
    },
    {
      title: "Created Date",
      dataIndex: "createdTime",
      key: "createdTime",
      width: 220,
      align: "left",
      render: (date: any) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Status",
      dataIndex: "actualStatus",
      key: "actualStatus",
      fixed: "right",
      width: 150,
      align: "left",
      render: (text, record) => {
        return (
          <>
            <div className="justify-start align-center">
              <h6
                className={`tb-status ms-1`}
                style={{
                  fontSize: "14px",
                  color:
                    text === "Approved"
                      ? "#28A745" // Green
                      : text === "Open"
                      ? "#007BFF" // Blue
                      : text === "In Progress"
                      ? "#FD7E14" // Orange
                      : text === "Rejected"
                      ? "#DC3545" // Red
                      : text === "Pending"
                      ? "#FFC107" // Yellow
                      : "black", // Default color
                }}
              >
                {text}
              </h6>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (text, record: any, index) => (
        <>
          <Button
            size="sm"
            // color=""
            onClick={() => {
              navigate(`/view_pr/${record.prId}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-purchase-requests-list"
      title="Purchase Requests"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <PurchaseRequestAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Status",
          options: filterStatus,
        },
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        excelExport: true,
        dataFilterTootip: "Filtering based on PR creation date",
      }}
    />
  );
};

export default ViewPurchaseRequests;
