import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import BudgetAside from "./BudgetAside";
import ViewBudgetDetails from "./ViewBudgetDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import budgetApis from "../../api/master/budget";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}
const widgetData: WidgetData[] = [
  {
    title: "Draft RFQs",
    count: 10,
    progress: 30,
    color: "#OO65BD",
  },
  {
    title: "PENDING",
    count: 5,
    progress: 50,
    color: "#20639B",
  },
  {
    title: "In Progress",
    count: 7,
    progress: 70,
    color: "#FA991C",
  },
  {
    title: "Converted to POs",
    count: 12,
    progress: 90,
    color: "#F6D55C",
  },
  {
    title: "Delivered",
    count: 15,
    progress: 100,
    color: "#OOCC99",
  },
];
const orderStatusCards: any = {
  // draft: {
  //   color: "#e85347",
  //   lable: "Draft Purchase Order",
  // },

  pending: {
    color: "#09c2de",
    lable: "PENDING",
  },
  withSuppliers: {
    color: "#364a63",
    lable: "Order With Suppliers",
  },
  invoiced: {
    color: "#1ee0ac",
    lable: "Invoiced",
  },
  // deliveredGrn: {
  //   color: "#2263b3",
  //   lable: "Delivered GRN",
  // },
  shortClosed: {
    color: "rgb(249, 140, 69)",
    lable: "Short Closed",
  },
};
const PoStatusColors: any = {
  PENDING: "#09c2de",
  Approved: "#1ee0ac",
  // Rejected: "#e85347",
  // "Pending Change Approval": "#09c2de",
  // "Accepted by Supplier": "#1ee0ac",
  // "Rejected by Supplier": "#e85347",
  // "To be Invoiced": "#2263b3",
  // "To be Delivered": "#2263b3",
};
const PoStatusIcons: any = {
  PENDING: "alert-circle",
  "To be Invoiced": "alert-circle",
  Approved: "check-circle",
  Rejected: "info",
  "Pending Change Approval": "alert-circle",
  "Accepted by Supplier": "check-circle",
  "To be Delivered": "check-circle",
  "Rejected by Supplier": "info",
};
const PoStatusIconsColors: any = {
  PENDING: "secondary",
  "To be Invoiced": "secondary",
  Approved: "success",
  Rejected: "danger",
  "Pending Change Approval": "secondary",
  "To be Delivered": "success",
  "Accepted by Supplier": "success",
  "Rejected by Supplier": "danger",
};

const PendingApprovalBudget: FC = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Budget Id",
      dataIndex: "budgetId",
      key: "budgetId",
      width: 120,
      fixed: "left",
      // render: (text) => (
      //   <div className="align-left">
      //     <span>{text}</span>
      //   </div>
      // ),
    },
    {
      title: "Budget Name",
      dataIndex: "budgetName",
      key: "budgetName",
      width: 150,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Budget For",
    //   dataIndex: "budgetFor",
    //   key: "budgetFor",
    //   width: 120,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Planned Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 150,
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Planned End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 200,
      render: (text: any, record: any) => (
        <p className="justify-end">{formatCurrency(Number(text), "INR")}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (status: any) => (
        <div className="justify-start align-center">
          <Icon
            className={`text-${PoStatusIconsColors[status]}`}
            name={`${PoStatusIcons[status]}`}
            style={{ fontSize: "20px" }}
          />
          <h6
            className={`tb-status ms-1`}
            style={{
              color: `${PoStatusColors[status]}`,
              fontSize: "14px",
            }}
          >
            {status}
          </h6>
        </div>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 160, // Increase width to accommodate additional buttons
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view_budget_details/${record.headerId}`);
              // setViewBudgetDetails({
              //   status: record.status,
              //   headerId: record.headerId,
              // });
            }}
          >
            <Icon name="focus" />
          </Button>

          {/* <Button
            size="sm"
            color="success"
            onClick={() => {
              // Handle approve action here
              handleApprove(record.headerId);
            }}
            className="mx-1" // Adds a small margin between buttons
          >
            <Icon name="check" title="Approve" />
          </Button>

          <Button
            size="sm"
            color="danger"
            onClick={() => {
              // Handle reject action here
              handleReject(record.headerId);
            }}
          >
            <Icon name="cross" tile="Reject" />
          </Button> */}
        </>
      ),
    },
  ]);

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="pendingApproval-budget"
        title="Budget - Pending Approvals"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <BudgetAside updateSm={setSm} sm={sm} />
          </div>
        }
        selectFilters={[
          {
            title: "Status",
            options: [
              { value: "Approved", label: "Approved" },
              { value: "Pending", label: "Pending" },
            ],
          },
        ]}
        extra={{
          tableScrollX: 1100,
        }}
      />
    </>
  );
};

export default PendingApprovalBudget;
