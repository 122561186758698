import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Badge, Spinner } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Drawer,
  Collapse as AntCollapse,
  Descriptions,
  Popover,
  Modal,
  Form,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import CreditDebitNoteAside from "./CNAside";
import creditDebitNoteApis from "../../api/credit-debit-note/credit-debit-note";
import { formatCurrency } from "../../utils/Utils";
import dayjs from "dayjs";
import axios from "axios";
import { render } from "@testing-library/react";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Panel } = AntCollapse;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const ViewPendingApprovalCN: FC = () => {
  const [disableAccept, setDisableAccept] = useState(false);
  const [disableReject, setDisableReject] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [billingAddress, setBillingAddress] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [attachments, setAttachments] = useState<any>([]);
  const [signature, setSignature] = useState<any>([]);

  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if (showRejectModal == false) {
      setDisableReject(false);
    }
  }, [showRejectModal]);

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Credit/Debit #",
      dataIndex: "noteId",
      key: "noteId",
      width: 200,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Reference #",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      width: 100,
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },

    {
      title: "Type",
      dataIndex: "noteType",
      key: "noteType",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
      width: 80,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => <span>{FormatDate(date)}</span>,
      width: 170,
    },
    {
      title: "Credit/Debit Amount",
      dataIndex: "noteAmount",
      key: "noteAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, "INR")}</span>
        </div>
      ),
    },
    {
      title: "Utilized Amount",
      dataIndex: "utilizedAmount",
      key: "utilizedAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, "INR")}</span>
        </div>
      ),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "creditDebitNoteStatus",
      key: "creditDebitNoteStatus",
      width: 120,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 80,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              fetchDataById(record?.noteNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);

  const drawerColumn: any = [
    {
      title: "Line #",
      dataIndex: "invoiceLineNo",
      key: "invoiceLineNo",
      width: 100,
      align: "center",
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 150,
      align: "center",
      fixed: "left",
    },
    {
      title: "HSN Code",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 100,
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      align: "center",
      render: (text: any, record: any) => (
        <span>
          {Number(text).toFixed(2)} {record?.uomShortCode}
        </span>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      align: "center",
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      align: "center",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 170,
      align: "center",
      render: (date: any) => <span>{FormatDate(date)}</span>,
    },
  ];
  const fetchList = async () => {
    try {
      const res =
        await creditDebitNoteApis.fetchAllUserPendingConfirmationCreditDebitNotes();
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setFilteredData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const fetchDataById = async (id: any) => {
    try {
      const res = await creditDebitNoteApis.fetchCreditDebitNote(id);
      const { data, status, message } = res;
      if (status) {
        setViewDrawer(true);
        setDrawerData(data);
        const { dms } = data;
        if (dms?.length > 0) {
          const _signature = dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: data?.signatureLabel,
              isActive: true,
            });
          }
          const _termsAndConditions = dms?.find(
            (item: any) => item.moduleFileType == "termsAndConditions"
          );
          if (_termsAndConditions) {
            const data: any = await fetchHtmlContentFromUrl(
              _termsAndConditions?.filePath
            );
            setTermsAndConditions(data);
          }
          const _instructions = dms?.find(
            (item: any) => item.moduleFileType == "instructions"
          );
          if (_instructions) {
            const data: any = await fetchHtmlContentFromUrl(
              _instructions?.filePath
            );
            setInstructions(data);
          }
          const _attachments = dms?.filter(
            (item: any) => item.moduleFileType == "attachments" && item.docHash
          );
          if (_attachments) {
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }
        setBillingAddress(data?.billingAddress);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };
  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const approveCN = async (id: any) => {
    try {
      const res = await creditDebitNoteApis.confirmCreditDebitNote(id);
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        fetchList();
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableAccept(false);
  };
  const disapproveCN = async (reason: any) => {
    try {
      const res = await creditDebitNoteApis.rejectCreditDebitNote(
        drawerData?.noteNumber,
        reason
      );
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        fetchList();
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableReject(false);
  };
  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="pending-approval-credit-debit-notes"
        title="Pending Approval"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <CreditDebitNoteAside updateSm={setSm} sm={sm} />
          </div>
        }
        extra={{
          tableScrollX: 1100,
          dateRange: false,
          excelExport: false,
        }}
      />
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong> {drawerData?.noteId}</strong>
            <>
              <div style={{ textAlign: "right" }}>
                {disableAccept ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setDisableAccept(true);
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Confirm it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setViewDrawer(false);
                          approveCN(drawerData?.noteNumber);
                        } else {
                          setDisableAccept(false);
                        }
                      });
                    }}
                    color="primary"
                    className="m-3 mt-0 mb-0"
                  >
                    Confirm
                  </Button>
                )}
                {disableReject ? (
                  <Button color="light" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setDisableReject(true);
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Reject it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setViewDrawer(false);
                          setShowRejectModal(true);
                        } else {
                          setDisableReject(false);
                        }
                      });
                    }}
                    color="light"
                    className="m-3 mt-0 mb-0"
                  >
                    Reject
                  </Button>
                )}
              </div>
            </>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{
          body: { paddingBottom: 80, paddingTop: 0 },
          header: { padding: 10 },
        }}
      >
        {drawerData ? (
          <>
            <AntCollapse defaultActiveKey={["1", "2", "3"]}>
              <Panel header="General Information" key="1">
                <div className={`tab-pane active}`}>
                  <Col className="justify-start w-100  flex-wrap mt-0 p-0">
                    <div className="w-30 border-end ">
                      <div className="data-col align-center pt-1">
                        <div className="data-label w-30">Reference #</div>
                        <div className="data-value w-70">
                          {drawerData?.referenceNumber || "--"}
                        </div>
                      </div>
                      <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Supplier</div>
                        <div className="data-value w-70 ">
                          {drawerData?.supplierName}
                        </div>
                      </div>

                      <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Note Type</div>
                        <div className="data-value w-70 ">
                          <Badge color="outline-success">
                            {drawerData?.noteType || "--"}
                          </Badge>
                        </div>
                      </div>

                      <div className="data-col align-center pt-1">
                        <div className="data-label w-30">Note Date</div>
                        <div className="data-value w-70">
                          {drawerData?.noteDate
                            ? dayjs(drawerData?.noteDate).format("DD-MMM-YYYY")
                            : "--"}
                        </div>
                      </div>
                    </div>
                    <div className="w-70 paddingLeft10  ">
                      <div className="data-col align-center pt-1">
                        <div className="data-label w-25">Approval Required</div>
                        <div className="data-value w-75">
                          {!drawerData?.autoConfirmed ? (
                            <Badge color="outline-success">Yes</Badge>
                          ) : (
                            <Badge color="outline-danger">No</Badge>
                          )}
                        </div>
                      </div>

                      <div className="data-col align-center pt-1">
                        <div className="data-label w-25">Billing Address</div>
                        <div className="data-value w-75">
                          <Popover
                            content={
                              <div>
                                <p>{drawerData?.billingAddressDetails}</p>
                              </div>
                            }
                            title={
                              <span style={{ color: "#2263b3" }}>
                                Billing Address
                              </span>
                            }
                          >
                            <span
                              style={{
                                color: "#2263b3",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </span>
                          </Popover>
                        </div>
                      </div>
                      <div className="data-col align-center pt-1">
                        <div className="data-label w-25">Delivery Address</div>
                        <div className="data-value w-75">
                          <Popover
                            content={
                              <div>
                                <p>{drawerData?.deliveryAddressDetails}</p>
                              </div>
                            }
                            title={
                              <span style={{ color: "#2263b3" }}>
                                Delivery Address
                              </span>
                            }
                          >
                            <span
                              style={{
                                color: "#2263b3",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </span>
                          </Popover>
                        </div>
                      </div>
                      <div className="data-col align-center pt-1">
                        <div className="data-label w-25">Description</div>
                        <div className="data-value w-75">
                          {drawerData?.description || "--"}
                        </div>
                      </div>
                      <div className="data-col align-center pt-1">
                        <div className="data-label w-25">Remarks</div>
                        <div className="data-value w-75">
                          {drawerData?.remarks || "--"}
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </Panel>
              <Panel
                header={
                  drawerData?.creditDebitNoteLines?.length > 0
                    ? "Material Details"
                    : "Credit Note Details"
                }
                key="2"
              >
                <div className="table-responsive poTable">
                  {drawerData?.creditDebitNoteLines?.length > 0 ? (
                    <div
                      style={{
                        width: screenSize - 300 + "px",
                        margin: "auto",
                      }}
                    >
                      <Table
                        className="customTable"
                        columns={drawerColumn?.map((col: any) => {
                          if (
                            [
                              "unitPrice",
                              "basicAmount",
                              "netAmount",
                              "grossTotal",
                            ].includes(col?.dataIndex)
                          ) {
                            return {
                              ...col,
                              render: (text: any, record: any) => {
                                return (
                                  <div className="justify-end">
                                    {formatCurrency(
                                      Number(text),
                                      drawerData.currencyCode
                                    )}
                                  </div>
                                );
                              },
                            };
                          }
                          if (col?.children?.length > 0) {
                            return {
                              ...col,
                              children: col?.children?.map((_col: any) => {
                                if (_col?.title == "Amount") {
                                  return {
                                    ..._col,
                                    render: (text: any, record: any) => {
                                      return (
                                        <div className="justify-end">
                                          {formatCurrency(
                                            Number(text),
                                            drawerData.currencyCode
                                          )}
                                        </div>
                                      );
                                    },
                                  };
                                }
                                return _col;
                              }),
                            };
                          }
                          if (!col.editable) {
                            return col;
                          }
                          return {
                            ...col,
                            onCell: (record: any) => ({
                              record,
                              inputType:
                                col.dataIndex === "quantity"
                                  ? "number"
                                  : "text",
                              dataIndex: col.dataIndex,
                              title: col.title,
                              editing: true,
                            }),
                          };
                        })}
                        dataSource={drawerData?.creditDebitNoteLines?.map(
                          (field: any) => {
                            return {
                              ...field,
                              key: field.lineNo,
                            };
                          }
                        )}
                        scroll={{ x: "max-content" }}
                        bordered
                        size="small"
                        pagination={false}
                      />
                    </div>
                  ) : (
                    <Row className="m-2 mb-0 w-50">
                      <Descriptions
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size="small"
                        labelStyle={{ fontWeight: "bold" }}
                        contentStyle={{ fontWeight: "normal" }}
                      >
                        <Descriptions.Item label="Line Name">
                          <strong> Amount</strong>
                        </Descriptions.Item>
                        {drawerData?.jsonLineData?.map((line: any) => {
                          return (
                            <>
                              <Descriptions.Item label={line?.name}>
                                {formatCurrency(
                                  Number(line?.noteAmount),
                                  drawerData.currencyCode
                                )}
                              </Descriptions.Item>
                            </>
                          );
                        })}
                        <Descriptions.Item label="Total Amount">
                          <strong>
                            {formatCurrency(
                              Number(drawerData?.noteAmount),
                              drawerData.currencyCode
                            )}
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                    </Row>
                  )}

                  <Row className="m-4 justify-content-end ">
                    {signature?.isActive && (
                      <div className="signatureBtnWrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <strong>Signature</strong>
                        </div>
                        <div
                          style={{
                            minHeight: signature?.image ? "0px" : "120px",
                            display: signature?.image ? "block" : "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {signature?.image && (
                            <>
                              <div className="signatueImgWrapper border">
                                <img
                                  src={signature?.image}
                                  alt="Signed"
                                  style={{ width: "160px" }}
                                />
                              </div>

                              <div>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="signatureLabel"
                                  >
                                    Signature Label
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="signatureLabel"
                                    onChange={(e) => {}}
                                    disabled
                                    defaultValue={
                                      signature?.label || "Authorised Signatory"
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </Row>
                </div>
              </Panel>
              <Panel header="Other Information" key="3">
                <Descriptions title="" className="custom-description-title">
                  <Descriptions.Item label="Instructions">
                    <span
                      style={{
                        color: "#2263b3",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setInstructionsVisible(true)}
                    >
                      View
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Terms and Conditions">
                    <span
                      style={{
                        color: "#2263b3",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setTermsVisible(true)}
                    >
                      View
                    </span>
                  </Descriptions.Item>
                  {attachments?.length > 0 && (
                    <Descriptions.Item label="">
                      <div>
                        <div className="">
                          Attachments &nbsp;
                          <PaperClipOutlined />
                        </div>
                        <div>
                          {attachments?.map(
                            (attachment: any, index: number) => (
                              <div
                                key={index}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div>
                                  <strong>{index + 1}.</strong>
                                  &nbsp;
                                  {attachment.name}
                                  &nbsp; &nbsp; &nbsp; &nbsp;
                                </div>
                                <div>
                                  <a
                                    href={attachment.link}
                                    download={attachment.name}
                                    style={{ color: "black", fontSize: "18px" }}
                                  >
                                    <DownloadOutlined />
                                  </a>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </Panel>
            </AntCollapse>
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
        <DisapproveCN
          isModalVisible={showRejectModal}
          setIsModalVisible={setShowRejectModal}
          reject={disapproveCN}
        />
        <Modal
          title={undefined}
          open={instructionsVisible}
          footer={null}
          onCancel={() => {
            setInstructionsVisible(false);
          }}
          width="60%"
          bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        >
          <div className="d-flex">
            <h5 className="m-1">Instructions </h5>
          </div>
          {instructions ? (
            <div
              dangerouslySetInnerHTML={{
                __html: instructions || "",
              }}
              className="m-1"
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Empty description="No Instructions Available for this Credit Note" />
            </div>
          )}
        </Modal>
        <Modal
          title={undefined}
          open={termsVisible}
          footer={null}
          onCancel={() => {
            setTermsVisible(false);
          }}
          width="60%"
          bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        >
          <div className="d-flex">
            <h5 className="m-1">Terms and Conditions</h5>
          </div>
          {instructions ? (
            <div
              dangerouslySetInnerHTML={{
                __html: termsAndConditions || "",
              }}
              className="m-1"
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Empty description="No Terms and Conditions Available for this Credit Note" />
            </div>
          )}
        </Modal>
      </Drawer>
    </>
  );
};
const DisapproveCN = ({ isModalVisible, setIsModalVisible, reject }: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        reject(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Reason for Rejection"
      open={isModalVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="rejectForm"
        requiredMark={false}
      >
        <Form.Item
          name="reason"
          label={undefined}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </Modal>
  );
};
export default ViewPendingApprovalCN;
