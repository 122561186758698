import React, { useState, FC } from "react";
import { Icon, TooltipComponent } from "../../components/Component";
import FormatDate from "../masters/DateFormater";

import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";

import DispatchAside from "./dispatchAside";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
import { Button } from "reactstrap";

const ViewAllDispatch: FC = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Dispatch #",
      dataIndex: "dispatchId",
      key: "dispatchId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 170,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "grnStatus",
      key: "grnStatus",
      width: 180,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.seller;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 80,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view-dispatch-details/${record.dispatchNumber}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-dispatch-list"
      title="Pending GRN's"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <DispatchAside updateSm={setSm} sm={sm} />
        </div>
      }
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        dataFilterTootip: "Filtering based on Dispatch date",
      }}
    />
  );
};

export default ViewAllDispatch;
