import React from "react";
import ReportTemplate2 from "../reportTemplate2";

function BudgetTrackReport() {
  const columns: any = [
    {
      title: "Budget ID",
      key: "budgetId",
      dataIndex: "budgetId",
      width: 150,
    },
    {
      title: "Budget Name",
      key: "budgetName",
      dataIndex: "budgetName",
      width: 250,
    },
    {
      title: "Budget Line ID",
      key: "budgetLineId",
      dataIndex: "budgetLineId",
      width: 100,
    },
    {
      title: "Budget Line Name",
      key: "budgetLineName",
      dataIndex: "budgetLineName",
      width: 250,
    },
    {
      title: "Budget Value",
      key: "budgetValue",
      dataIndex: "budgetValue",
      width: 150,
      render: (value: number) => `  ${value.toLocaleString()}`,
    },
    {
      title: "Site Name",
      key: "siteName",
      dataIndex: "siteName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 150,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 150,
    },
    {
      title: "Consumption Value",
      key: "consumptionValue",
      dataIndex: "consumptionValue",
      width: 150,
      render: (value: number) => `  ${value.toLocaleString()}`,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 150,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Budget Tracking"
      moduleKey={window.location.pathname}
      columns={columns}
      extra={{
        tableScrollX: 1700,
      }}
      filtersOption={{
        site: true,
        category: true,
        material: true,
        dateRange: true,
      }}
      previousPath="/view-budget-reports"
    />
  );
}

export default BudgetTrackReport;
