import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Empty,
  Flex,
  Spin,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import PurchaseOrderAside from "./PurchaseOrderAside";
import ViewOrderDetails from "./ViewOrderDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
// const { Option } = Select;

const ViewPurchaseOrdersList: FC = () => {
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);
  const [widgetData, setWidgetData] = useState<any>({});
  const [orderStatus, setOrderStatus] = useState<any>([]);
  const columns = [
    // {
    //   title: "#",
    //   key: "#",
    //   width: 50,
    //   fixed: "left",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Order #",
      dataIndex: "orderId",
      key: "orderId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 150,
      fixed: "left",
      render: (date: any) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Supplier ",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 25 ? `${text?.substring(0, 22)}... ` : text}
            </span>
            {text?.length > 25 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Requested Delivery Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 200,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 150,
    },
    {
      title: "GRN Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
      render(value: any, record: any, index: any) {
        const grnStatus: any = {
          FullyDelivered: "Fully Delivered",
          PartiallyDelivered: "Partially Delivered",
        };
        return <p className="tb-status">{grnStatus[value] || value}</p>;
      },
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 200,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "View",
      key: "actions",
      fixed: "right",
      width: 70,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view-purchase-order/${record.orderNumber}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchWidgetCount();
    fetchDerivedOrderStatusDropdown();
  }, []);

  const fetchWidgetCount = async () => {
    try {
      const res = await purchaseOrderApis.fetchWidgetCount();
      const { data, status, message } = res;
      if (status) {
        setWidgetData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const fetchDerivedOrderStatusDropdown = async () => {
    try {
      const res = await purchaseOrderApis.fetchDerivedOrderStatusDropdown();
      console.log("status");
      const { data, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = Object.keys(data).map((key) => {
          return {
            label: key,
            value: data[key],
          };
        });
        console.log("dateresp", dateresp);
        setOrderStatus(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const excelExport = async () => {
    // try {
    //   const res = await purchaseOrderApis.excelExport();
    //   const { data, status, message } = res;
    //   if (status) {
    //     window.location.href = data.url;
    //   } else {
    //     Swal.fire("Error", message, "error");
    //   }
    // } catch (error) {}
  };

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-purchase-order"
      title="Purchase Orders"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <PurchaseOrderAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Order Status",
          placeholder: "Select Status",
          options: orderStatus,
        },
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        excelExport: true,
        dataFilterTootip: "Filtering based on Purchase Order date",
      }}
      // topRightJSX={
      //   <Button color="primary" onClick={() => excelExport()}>
      //     <Icon name="download"></Icon>
      //     <span>Excel Export </span>
      //   </Button>
      // }
      widget={{
        isActive: true,
        list: [
          {
            title: "Approved",
            key: "approved",
            color: "#OO65BD",
            percentage: `${widgetData?.approvedPercentage || 0}`,
            value: `${widgetData?.approvedCount || 0}`,
          },
          {
            title: "Pending Approval",
            key: "pending",
            color: "#20639B",
            percentage: `${widgetData?.pendingApprovalPercentage || 0}`,
            value: `${widgetData?.pendingApprovalCount || 0}`,
          },
          {
            title: "Supplier Changes",
            key: "supplierChanges",
            color: "#FA991C",
            percentage: `${widgetData?.supplierChangesPercentage || 0}`,
            value: `${widgetData?.supplierChangesCount || 0}`,
          },
          {
            title: "Changes Requested",
            key: "changesRequested",
            color: "#FA991C",
            percentage: `${widgetData?.changesRequestedPercentage || 0}`,
            value: `${widgetData?.changesRequestedCount || 0}`,
          },
          {
            title: "To be Invoiced",
            key: "toBeInvoiced",
            color: "#FA991C",
            percentage: `${widgetData?.tobeInvoicedPercentage || 0}`,
            value: `${widgetData?.tobeInvoicedCount || 0}`,
          },
        ],
      }}
    />
  );
};

export default ViewPurchaseOrdersList;
