import makeApiCall from "./axiosIndex";
const materialsApis = {
  fetchProduct: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchProduct" }),
  deleteProductById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteProductById?id=${id}`,
    }),
  createProduct: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createProduct",
      data,
    }),
  updateProductById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: "/buyer/updateProductById",
      params: { id: id },
      data,
    }),
  fetchProductById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchProductById",
      params: { id },
    }),
  deletePrefferedSuppliersById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deletePrefferedSuppliersById`,
      params: { id },
    }),
  findCategoryByMaterialType: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/findCategoryByMaterialType`,
      params: { id },
    }),
  fetchProductWithPagenation: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/buyer/fetchProductWithPagenation",
      method: "get",
      params,
    }),
  fetchCategoryFilteredInProduct: (): Promise<any> =>
    makeApiCall<any>({
      url: "/buyer/fetchCategoryFilteredInProduct",
      method: "get",
    }),
};

export default materialsApis;
