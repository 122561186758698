import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  TooltipComponent,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Progress, Table, Select, Input, Flex, Spin, Empty } from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import quotationApis from "../../api/master/quotations";
import moment from "moment";
import Swal from "sweetalert2";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
  mappingStatus: string;
}

const RFQView: FC = () => {
  const [sm, setSm] = useState(false);

  const [widgetData, setWidgetData] = useState<WidgetData[]>([]);

  const [filterStatus, setfilterStatus] = useState<any>([]);

  useEffect(() => {
    fetchCounts();
    fetchStatusFilterOptions();
  }, []);

  const fetchCounts = async () => {
    try {
      const { response, status } = await quotationApis.fetchCounts();
      if (status) {
        const counts = response.data;
        const colors = ["#OO65BD", "#20639B", "#FA991C", "#F6D55C", "#OOCC99"];
        let formatData = counts.map((item: any, index: number) => ({
          title: item.title,
          key: item.title,
          value: item.count,
          percentage: item.progress.toFixed(0),
          color: colors[index % colors.length],
        }));
        setWidgetData(formatData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStatusFilterOptions = async () => {
    try {
      const res = await quotationApis.fetchStatusFilterOptions();
      console.log("status");
      const { response, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = response.data;
        console.log("dateresp", dateresp);
        setfilterStatus(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const navigate = useNavigate();

  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate("/rfq-full-view", { state: { rfq } });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 200,
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleRFQClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
      align: "left",
    },
    {
      title: "Preferred Brands / Make",
      dataIndex: "preferredBrands",
      key: "preferredBrands",
      width: 200,
      // sorter: (a, b) => a.prefferedBrands.localeCompare(b.prefferedBrands),
      // align: "left",
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      width: 250,
      // align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`quotationDescription-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`quotationDescription-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Submission Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Cost Center",
      dataIndex: "costCenterName",
      key: "costCenterName",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`costCenterName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`costCenterName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, record) => {
        return (
          <>
            <div className="justify-start align-center">
              <h6
                className={`tb-status ms-1`}
                style={{
                  fontSize: "14px",
                  color:
                    text === "Awarded"
                      ? "#28A745" // Green
                      : text === "Requested"
                      ? "#007BFF" // Blue
                      : text === "Negotiated"
                      ? "#FD7E14" // Orange
                      : text === "Quoted"
                      ? "#6F42C1" // Purple
                      : text === "Rejected"
                      ? "#DC3545" // Red
                      : text === "Pending"
                      ? "#FFC107" // Yellow
                      : "black", // Default color
                }}
              >
                {text == "Quoted" ? "Quotation Received" : text}
              </h6>
            </div>
          </>
        );
      },
      width: 150,
      sorter: (a: any, b: any) =>
        a.mappingStatus.localeCompare(b.mappingStatus),
      align: "left",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record: any, index) => (
        <>
          <Button
            size="sm"
            // color=""
            onClick={() => {
              navigate(`/view/${record.quotationId}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-rfqs"
      title="Quotations"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <QuotationsAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Status",
          placeholder: "Select Status",
          options: filterStatus,
        },
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        excelExport: true,
        dataFilterTootip: "Filtering based on quotation creation date",
      }}
      widget={{
        isActive: true,
        list: widgetData,
      }}
    />
  );
};

export default RFQView;
