import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Spinner } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Tooltip,
  Drawer,
  Modal,
  Form,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import dispatchApis from "../../api/dispatch/dispatch";
import DispatchAside from "./paymentsNav";
import paymentApis from "../../api/payments/payments";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import usersApis from "../../api/master/users";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const PendingPaymentApprovals: FC = () => {
  const [disableAccept, setDisableAccept] = useState<boolean>(false);
  const [disableReject, setDisableReject] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  useEffect(() => {
    if (showRejectModal == false) {
      setDisableReject(false);
    }
  }, [showRejectModal]);

  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Payment #",
      dataIndex: "paymentId",
      key: "PaymentId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 170,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Received Amount",
      dataIndex: "payableAmount",
      key: "payableAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(Number(text), "INR")}
              {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
            </span>
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 220,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 70,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewDrawer(true);
              fetchPaymentDetails(record?.paymentNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  const fetchPaymentDetails = async (paymentNumber: any) => {
    try {
      const res = await paymentApis.fetchPayment(paymentNumber);
      const { data, status, message } = res;
      if (status) {
        setDrawerData(data);
        setApproverIds(
          data?.paymentApprovals
            ?.filter((item: any) => item?.status == "Pending")
            ?.map((item: any) => item?.userId)
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const drawerColumns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 120,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 170,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Payable Amount",
      dataIndex: "payableAmount",
      key: "payableAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(Number(text), "INR")}
              {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
            </span>
          </div>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
  ];

  const approvePayment = async () => {
    try {
      const res = await paymentApis.approvePayment(drawerData?.paymentNumber);
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        // fetchData();
        setRefreshToggle(!refreshToggle);
      }
    } catch (error) {}
    setDisableAccept(true);
  };

  const disapprovePayment = async (reason: any) => {
    try {
      const res = await paymentApis.disapprovePayment(
        drawerData?.paymentNumber,
        reason
      );
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        // fetchData();
        setRefreshToggle(!refreshToggle);
      }
    } catch (error) {}
    setDisableReject(false);
  };

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="pending-payment-approvals"
        title="Pending Payment Approvals"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <DispatchAside updateSm={setSm} sm={sm} />
          </div>
        }
        refresh={refreshToggle}
        extra={{
          tableScrollX: 1100,
        }}
      />
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Invoice Payables</strong>
            <div style={{ textAlign: "right" }}>
              {disableAccept ? (
                <Button color="primary" type="button">
                  <Spinner size="sm" color="light" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setDisableAccept(true);
                    setViewDrawer(false);
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Approve it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        approvePayment();
                      } else {
                        setDisableAccept(false);
                      }
                    });
                  }}
                  color="primary"
                  className="m-3 mt-0 mb-0"
                >
                  Approve
                </Button>
              )}
              {disableReject ? (
                <Button color="light" type="button">
                  <Spinner size="sm" color="light" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setDisableReject(true);
                    setViewDrawer(false);
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Reject it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setShowRejectModal(true);
                      } else {
                        setDisableReject(false);
                      }
                    });
                  }}
                  color="light"
                  className="m-3 mt-0 mb-0"
                >
                  Reject
                </Button>
              )}
            </div>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {drawerData ? (
          <>
            <div className="d-flex justify-content-between p-3 pb-0 pt-0">
              <div>
                <strong>Remark</strong>
                <p>{drawerData?.remarks}</p>
              </div>
              <div>
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div className="justify-end">
                      <strong>Payable Amount Total &nbsp; &nbsp; </strong>
                    </div>
                    <strong className="fw-bold">
                      {formatCurrency(
                        Number(drawerData?.payableAmount || 0),
                        drawerData?.currencyCode || "INR"
                      )}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive poTable">
              <div
                style={{
                  width: screenSize - 300 + "px",
                  margin: "0px auto 15px auto",
                }}
              >
                <Table
                  className="customTable"
                  columns={drawerColumns}
                  dataSource={drawerData?.paymentLines}
                  scroll={{ x: 1000 }}
                  bordered
                  size="middle"
                  pagination={false}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
      </Drawer>
      <DisapprovePayment
        isModalVisible={showRejectModal}
        setIsModalVisible={setShowRejectModal}
        reject={disapprovePayment}
      />
    </>
  );
};

const DisapprovePayment = ({
  isModalVisible,
  setIsModalVisible,
  reject,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        reject(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Reason for Rejection"
      open={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="rejectForm"
        requiredMark={false}
      >
        <Form.Item
          name="reason"
          label={undefined}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </Modal>
  );
};
export default PendingPaymentApprovals;
