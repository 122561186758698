import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import BudgetAside from "./BudgetAside";
import ViewBudgetDetails from "./ViewBudgetDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import budgetApis from "../../api/master/budget";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../utils/Utils";
import dayjs from "dayjs";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Option } = Select;

const PoStatusColors: any = {
  PENDING: "#09c2de",
  Approved: "#1ee0ac",
  // Rejected: "#e85347",
  // "Pending Change Approval": "#09c2de",
  // "Accepted by Supplier": "#1ee0ac",
  // "Rejected by Supplier": "#e85347",
  // "To be Invoiced": "#2263b3",
  // "To be Delivered": "#2263b3",
};
const PoStatusIcons: any = {
  PENDING: "alert-circle",
  "To be Invoiced": "alert-circle",
  Approved: "check-circle",
  Rejected: "info",
  "Pending Change Approval": "alert-circle",
  "Accepted by Supplier": "check-circle",
  "To be Delivered": "check-circle",
  "Rejected by Supplier": "info",
};
const PoStatusIconsColors: any = {
  PENDING: "secondary",
  "To be Invoiced": "secondary",
  Approved: "success",
  Rejected: "danger",
  "Pending Change Approval": "secondary",
  "To be Delivered": "success",
  "Accepted by Supplier": "success",
  "Rejected by Supplier": "danger",
};

const ViewBudgetList: FC = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [filterStatus, setfilterStatus] = useState<any>([]);
  useEffect(() => {
    fetchStatusFilterOptions();
  }, []);
  const fetchStatusFilterOptions = async () => {
    try {
      const res = await budgetApis.fetchStatusFilterOptions();
      console.log("status");
      const { response, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = response.data;
        console.log("dateresp", dateresp);
        setfilterStatus(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };
  const columns = [
    {
      title: "Budget Id",
      dataIndex: "budgetId",
      key: "budgetId",
      width: 200,
      fixed: "left",
      // render: (text) => (
      //   <div className="align-left">
      //     <span>{text}</span>
      //   </div>
      // ),
    },
    {
      title: "Budget Name",
      dataIndex: "budgetName",
      key: "budgetName",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Budget For",
    //   dataIndex: "budgetFor",
    //   key: "budgetFor",
    //   width: 120,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Planned Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 150,
      render: (text: any) => <span>{dayjs(text).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: "Planned End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (text: any) => <span>{dayjs(text).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 200,
      render: (text: any, record: any) => (
        <p className="justify-end">{formatCurrency(Number(text), "INR")}</p>
      ),
    },
    {
      title: "Allocated Value",
      dataIndex: "allocatedValue",
      key: "allocatedValue",
      width: 200,
      render: (text: number) => (
        <p className="justify-end">
          {text
            ? new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              }).format(text)
            : formatCurrency(0, "INR")}
        </p>
      ),
    },
    {
      title: "Utilized Value",
      dataIndex: "utilizedValue",
      key: "utilizedValue",
      width: 200,
      render: (text: number, record: any) => {
        const budgetValue = record.budgetValue || 0;
        const percentage = budgetValue ? (text / budgetValue) * 100 : 0;

        // Determine color based on percentage
        let color = "black"; // Default color
        if (percentage >= 75) color = "red";
        else if (percentage >= 50) color = "orange";
        else if (percentage >= 25) color = "yellow";
        else color = "green";

        return (
          <div style={{ color }}>
            {new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            }).format(text)}{" "}
            ({percentage.toFixed(2)}%)
          </div>
        );
      },
    },

    // {
    //   title: "GRN Status",
    //   dataIndex: "deliveryStatus",
    //   key: "deliveryStatus",
    //   width: 200,
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (status: any) => (
        <div className="justify-start align-center">
          <Icon
            className={`text-${PoStatusIconsColors[status]}`}
            name={`${PoStatusIcons[status]}`}
            style={{ fontSize: "20px" }}
          />
          <h6
            className={`tb-status ms-1`}
            style={{
              color: `${PoStatusColors[status]}`,
              fontSize: "14px",
            }}
          >
            {status}
          </h6>
        </div>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 120,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view_budget_details/${record.headerId}`);
              // setViewBudgetDetails({
              //   status: record.status,
              //   headerId: record.headerId,
              // });
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-budget-list"
      title="Budget"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <BudgetAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Status",
          placeholder: "Select Status",
          options: filterStatus,
        },
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        excelExport: true,
        dataFilterTootip: "Filtering based on budget creation date",
      }}
    />
  );
};

export default ViewBudgetList;

// ) : (
//   <Block>
//     <ViewBudgetDetails BudgetData={viewBudgetDetails}
//       addRow={BudgetCreation}
//       showSubmitButton= {submitButton}
//     />
//   </Block>
// )}
