import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { useNavigate } from "react-router";

function MaterialSummary() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Site Name",
      key: "siteName",
      dataIndex: "siteName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 150,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 150,
    },
    {
      title: "BOQ QTY",
      key: "boqQty",
      dataIndex: "boqQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Requested QTY",
      key: "requestedQty",
      dataIndex: "requestedQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Purchased QTY",
      key: "purchasedQty",
      dataIndex: "purchasedQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Delivered QTY",
      key: "deliveredQty",
      dataIndex: "deliveredQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Issued QTY",
      key: "issueQyt",
      dataIndex: "issueQyt",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Utilized QTY",
      key: "utilizedQty",
      dataIndex: "utilizedQty",
      width: 100,
      render: (text: any, record: any) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }

        return (
          <div style={{ textAlign: "right" }}>
            {value === 0 ? (
              <span>{value.toFixed(2)}</span> // Render plain text for 0.00
            ) : (
              <span
                className="inlineTableCellLink"
                onClick={() => {
                  navigate(`/material-summary-list-detail-report`, {
                    state: {
                      materialName: record?.materialName,
                      materialId: record?.MaterialId,
                      categoryId: record?.categoryId,
                      siteId: record?.siteId,
                    },
                  });
                }}
              >
                {value.toFixed(2)}
              </span>
            )}
          </div>
        );
      },
      // render: (text: string) => {
      //   const value = Number(text); // Convert text to a number
      //   if (isNaN(value)) {
      //     return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
      //   }
      //   return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      // },
    },
    {
      title: "Damaged Qty",
      key: "damagedQty",
      dataIndex: "damagedQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Return Qty",
      key: "returnQty",
      dataIndex: "returnQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Material Flow Summary"
      moduleKey={window.location.pathname}
      columns={columns}
      filtersOption={{
        site: true,
        category: true,
        material: true,
      }}
      extra={{
        tableScrollX: 1700,
      }}
      previousPath="/view-material-management-reports"
    />
  );
}

export default MaterialSummary;
