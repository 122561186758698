import { UncontrolledDropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const DownloadDropdown = () => {
  const navigate = useNavigate();
  const downloadHighlighted = useSelector(
    (state) => state.highlight.downloadHighlighted
  );
  return (
    <UncontrolledDropdown>
      {/* <div
        className="nk-quick-nav-icon icon-status icon-status-na"
        onClick={() => navigate("/excelexport-requested-list")}
        style={{ cursor: "pointer" }}
      >
        <Icon name="ni ni-download"></Icon>
        {downloadHighlighted && <span className="highlight-dot"></span>}
      </div> */}
      {/* <div
        className={`nk-quick-nav-icon icon-status icon-status-na ${
          downloadHighlighted ? "bounce" : ""
        }`}
        onClick={() => navigate("/excelexport-requested-list")}
        style={{ cursor: "pointer", position: "relative" }}
      >
        <Icon name="ni ni-download"></Icon>
        {downloadHighlighted && <span className="highlight-dot"></span>}
      </div> */}
      <div
        className={`nk-quick-nav-icon icon-status icon-status-na ${
          downloadHighlighted ? "highlighted" : ""
        }`}
        onClick={() => navigate("/excelexport-requested-list")}
        style={{ cursor: "pointer", position: "relative" }}
      >
        <Icon name="ni ni-download"></Icon>
        {downloadHighlighted && <span className="highlight-dot"></span>}
      </div>
    </UncontrolledDropdown>
  );
};

export default DownloadDropdown;
