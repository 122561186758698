import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import { TableColumnsType, Table, Progress, Select, Input, Empty, Flex, Spin } from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import PurchaseOrderAside from "./PurchaseOrderAside";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const PurchaseOrderAprrovals: FC = () => {
  const [sm, setSm] = useState(false);
  
  const navigate = useNavigate();
  
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Order #",
      dataIndex: "orderId",
      key: "orderId",
      width: 200,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 150,
      fixed: "left",
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Supplier ",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 25 ? `${text?.substring(0, 22)}... ` : text}
            </span>
            {text?.length > 25 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Delivery Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 200,
      render: (date) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {formatCurrency(text, record.currencyCode)}
          </span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, record.currencyCode):"N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, record.currencyCode):"N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(!Number(record?.sgst) &&!Number(record?.cgst))?formatCurrency(text, record.currencyCode):"N/A"}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {formatCurrency(text, record.currencyCode)}
          </span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => <span>{FormatDate(date)}</span>,
      width: 150,
    },
    {
      title: "GRN Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
      render(value:any, record:any, index:any) {
        const grnStatus:any={
          "FullyDelivered":"Fully Delivered",
          "PartiallyDelivered":"Partially Delivered",
        }
        return (
          <p className="tb-status">{grnStatus[value]||value}</p>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 200,
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title||status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 70,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/approvals-purchase-order/${record.orderNumber}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);
 


  return (
    <TransactionFlowDefaultTemplate
      moduleKey="approvals-purchase-order"
      title="Pending Approvals"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <PurchaseOrderAside updateSm={setSm} sm={sm} />
        </div>
      }
      extra={{
        tableScrollX: 1100,
      }}
    />
  );
};

export default PurchaseOrderAprrovals;
