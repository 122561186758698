import React, { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import {
  Progress,
  Table,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
} from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";
import PurchaseRequestAside from "./PurchaseRequestAside";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import FormatDate from "../masters/DateFormater";
import dayjs from "dayjs";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
}

const PurchaseRequestAprrovals: FC = () => {
  const [sm, setSm] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectRecord, setRejectRecord] = useState<any>(null);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "PR #",
      dataIndex: "prSequence",
      key: "prSequence",
      fixed: "left",
      width: 200,
      // sorter: (a: any, b: any) => a.prSequence.localeCompare(b.prSequence),
      align: "left",
    },
    {
      title: "Indent Number",
      dataIndex: "referenceIndentNumber",
      key: "referenceIndentNumber",
      fixed: "left",
      width: 200,
      // sorter: (a: any, b: any) =>
        // a.referenceIndentNumber.localeCompare(b.rfqId),
      align: "left",
      // render: (text, record) => (
      //   <a onClick={() => handleRFQClick(record)}>{text}</a>
      // ),
    },
    {
      title: "Indent Date",
      dataIndex: "indentDate",
      key: "indentDate",
      // fixed: "left",
      width: 150,
      // sorter: (a: any, b: any) => a.indentDate.localeCompare(b.indentDate),
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 150,
      // sorter: (a, b) => a.siteName.localeCompare(b.siteName),
      align: "left",
    },
    {
      title: "Requested By",
      dataIndex: "requestByName",
      key: "requestByName",
      width: 150,
      // sorter: (a, b) => a.deadlineType.localeCompare(b.deadlineType),
      align: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "lastDateOfSubmission",
      key: "lastDateOfSubmission",
      width: 200,
      // sorter: (a, b) =>
        // new Date(a.lastSubmissionDate).getTime() -
        // new Date(b.lastSubmissionDate).getTime(),
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Delivery Location",
      dataIndex: "deliveryAddressName",
      key: "deliveryAddressName",
      width: 150,
      // sorter: (a: any, b: any) =>
        // a.deliveryAddressName.localeCompare(b.deliveryAddressName),
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
      // sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Created By",
      dataIndex: "createdUser",
      key: "createdUser",
      width: 150,
      align: "left",
    },
    {
      title: "Created Date",
      dataIndex: "createdTime",
      key: "createdTime",
      width: 150,
      align: "left",
      render: (date: any) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Status",
      dataIndex: "actualStatus",
      key: "actualStatus",
      fixed: "right",
      width: 150,
      // sorter: (a: any, b: any) => a.actualStatus.localeCompare(b.actualStatus),
      align: "left",
      render: (text, record) => {
        return (
          <>
            <div className="justify-start align-center">
              <h6
                className={`tb-status ms-1`}
                style={{
                  fontSize: "14px",
                  color:
                    text === "Approved"
                      ? "#28A745" // Green
                      : text === "Open"
                      ? "#007BFF" // Blue
                      : text === "In Progress"
                      ? "#FD7E14" // Orange
                      : text === "Rejected"
                      ? "#DC3545" // Red
                      : text === "Pending"
                      ? "#FFC107" // Yellow
                      : "black", // Default color
                }}
              >
                {text}
              </h6>
            </div>
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 160, // Increase width to accommodate additional buttons
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            // color=""
            onClick={() => {
              navigate(`/view_pr/${record.prId}`);
            }}
          >
            <Icon name="focus" />
          </Button>
         
        </>
      ),
    },
  ];


  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="approvals-purchase-request"
        title="Pending Purchase Request Approvals"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <PurchaseRequestAside updateSm={setSm} sm={sm} />
          </div>
        }
        selectFilters={[
          {
            title: "Status",
            options: [
              { value: "Open", label: "Open" },
              { value: "In Progress", label: "In Progress" },
              { value: "Pending", label: "Pending" },
            ],
          },
        ]}
        extra={{
          tableScrollX: 1100,
        }}
      />
    </>
  );

};

export default PurchaseRequestAprrovals;
