import React, { useEffect, useState } from "react";
import { useFormContext,  useFieldArray } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import AddNewMaterial from './AddNewMaterial';
import { Table, TableColumnsType } from 'antd';
import {  PaperClipOutlined } from '@ant-design/icons';
import { Icon } from "../../../components/Component";
import dayjs from "dayjs";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import Swal from "sweetalert2";
import supplierApis from "../../../api/master/supplier";

const RFQMaterials: React.FC<{ next: () => void, prev: () => void }> = (props: any) => {
  const { control, watch, handleSubmit } = useFormContext();
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);

  const [generalDetails, setGeneralDetails] = useState<any>();
  const [activeMaterial, setActiveMaterial] = useState<any>({
    action: null,
    index: null,
    item: null,
  });

  const previousFormDate = watch();
  const [needByDateType, setNeedByDateType] = useState<string>("");
  const [deliveryAddressTypeSet, setDeliveryAddressType] = useState<string>("");
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "items",
  });
  const [columns] = useState<TableColumnsType<any>>([
    // {
    //   title: 'MATERIAL ID',
    //   dataIndex: 'materialName',
    //   key: 'materialName',
    //   fixed: 'left' as const,
    //   width: 150,
    // },
    {
      title: 'Category',
      dataIndex: 'categoryLabel',
      key: 'categoryLabel',
      fixed: 'left' as const,
      // width: 200,
    },
    {
      title: 'Material Name',
      dataIndex: 'materialLabel',
      key: 'materialLabel',
      // width: 200,
    },
    {
      title: 'Specification',
      dataIndex: 'specification',
      key: 'specification',
      width: 200,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: any) => {
        const quantity = parseFloat(text);
        return !isNaN(quantity) ? quantity.toFixed(2) : text; // Only format if it's a number
      },
    },
    {
      title: 'Uom',
      dataIndex: 'uomLabel',
      key: 'uomLabel',
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      // width: 150,
      hidden: generalDetails?.needByDateType === "LINE_LEVEL" ? false : true,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    // {
    //   title: 'NEED BY DATE',
    //   dataIndex: 'needByDate',
    //   key: 'needByDate',
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setActiveMaterial({
                action: "edit",
                index: index,
                item: record,
              });
            }}
          >
            <Icon name="edit" />
          </Button>
          <Button size="sm" color="" onClick={() => remove(index)}>
            <Icon name="trash" />
          </Button>
        </>
      ),
    },
  ]);


  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setMaterialCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status, message } =
        await deliveryAddressApis.fetchDeliveryAddress();

      if (status) {
        setDeliveryAddressList(data);
      } else {
        console.log(message);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchDeliveryAddress();
    fetchCategorys()
    const {
      needByDateType,
      deliveryAddressType,
    } = previousFormDate;
   
    setGeneralDetails(previousFormDate);
    if (needByDateType) {
      setNeedByDateType(needByDateType);
    }
    if (deliveryAddressType) {
      console.log("deliveryAddressType:::1111", deliveryAddressType);
      setDeliveryAddressType(deliveryAddressType);
    }
  }, []);

  const ShowAddressPopOver = ({ id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    return (
      <ul>
        <li>{address?.addressLine1}</li>
        <li>{address?.addressLine2}</li>
        <li>
          {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
          {address?.pincode}
        </li>
      </ul>
    );
  };

  

  const addNewRow = () => {
    setActiveMaterial({
      action: "add",
      index: null,
      item: null,
    });
  };

 
  return (
    <>
      <form onSubmit={handleSubmit(() => { })}>
        <div style={{ overflow: 'auto' }}>
          <Table
            columns={columns}
            dataSource={fields}
            scroll={{ x: 'max-content' }} // Adjust this as necessary
            expandable={{
              expandedRowRender: (record: any) => (
                <div className="expandableBody">
                  <div style={{ flex: 1, textAlign: 'left' }}>
                    <p style={{ margin: 0 }}>{record.description}</p>
                    {record.file && record.file[0] && (
                      <div>
                        <PaperClipOutlined /> &nbsp;
                        <a
                          href={URL.createObjectURL(record.file[0])}
                          download={record.file[0]?.name}
                        >
                          {record.file[0]?.name}
                        </a>
                      </div>
                    )}
                  </div>
                  {deliveryAddressTypeSet === 'LINE_LEVEL' && record.deliveryAddressId && (
                    <div className="lineAddress" style={{ flex: 1 }}>
                      <h6>Delivery Address</h6>
                      <ShowAddressPopOver
                        id={record.deliveryAddressId}
                      />
                    </div>
                  )}
                </div>
              ),
            }}
            bordered
            size="small" // Making the table compact
            pagination={false}
            locale={{
              emptyText: (
                <span
                  style={{
                    height: "300px",
                    color: "#333",
                    fontSize: "16px",
                  }}
                >
                  Please click on "Add New Item" to add Quotation
                  lines.
                </span>
              ),
            }}
          />
          <Row
            style={{
              margin: "20px",
            }}
          >
            <Col className="justify-between">
              <a onClick={addNewRow} className="addNewItem">
                <Icon name="plus-round" />
                <span>Add New Item</span>
              </a>
            </Col>
          </Row>
        </div>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button title={fields.length === 0 ? "Please add materials" : ""} disabled={fields.length === 0} color="primary"
                type="submit" onClick={() => { props.next() }}>
                Next
              </Button>
            </li>
            <li>
              <Button color="primary" onClick={props.prev} className="btn-dim btn btn-secondary">
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
      {activeMaterial.action && (
        <AddNewMaterial
          modal={!!activeMaterial.action}
          closeModal={() => {
            setActiveMaterial({
              action: null,
              index: null,
              item: null,
            });
          }}
          materialCategoryOptions={materialCategoryOptions}
          append={(data: any) => {
            const _data = fields.find((item: any) => {
              if (
                item.materialName == data.materialName &&
                dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                item.deliveryAddressId == data.deliveryAddressId
              )

                return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            append(data);
          }}
          update={(index: any, data: any) => {
            const ItemsList = [...fields]
            const _data = ItemsList?.filter((item: any, idx) => idx != index)?.find((item: any) => {
              if (
                item.materialName == data.materialName &&
                dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                item.deliveryAddressId == data.deliveryAddressId
              )
                return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            update(index, data);
          }}
          formData={activeMaterial}
          deliveryAddressLevel={deliveryAddressTypeSet}
          needByDateType={needByDateType}
        />
      )}
    </>
  );
};

export default React.memo(RFQMaterials);
