import React, { useState, useEffect } from "react";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  // LoginLogTable,
  Button,
} from "../../../components/Component";

import dayjs from "dayjs";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setHighlight } from "../../../store/slices/misc/highlightSlice";

const ExcelExportRequestedList = () => {
  const [loginActivity, setLoginActivity] = useState([]);
  const [sm, updateSm] = useState(false);
  const dispatch = useDispatch();
  const downloadHighlighted = useSelector(
    (state) => state.highlight.downloadHighlighted
  );
  useEffect(() => {
    if (downloadHighlighted) {
      // Reset the highlight state to false
      dispatch(setHighlight(false));
    }
  }, [downloadHighlighted, dispatch]);
  const columns = [
    {
      title: "File ID",
      dataIndex: "reportId",
      key: "reportId",
      width: 200,
    },
    {
      title: "File Type",
      dataIndex: "fileType",
      key: "fileType",
      width: 200,
      render: (text) => <span>Excel</span>,
    },
    {
      title: "File Name",
      dataIndex: "reportType",
      key: "reportType",
      width: 250,
    },
    {
      title: "Date & Time",
      dataIndex: "requestedDate",
      key: "requestedDate",
      width: 200,
      render: (text) => (
        <span>{dayjs(text).format("DD-MM-YYYY hh:mm:ss")}</span>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: 200,
    },
    {
      title: "File Status",
      dataIndex: "status",
      key: "status",
      width: 200,
    },
    {
      title: "File Size (KB)",
      dataIndex: "fileSize",
      key: "fileSize",
      width: 200,
    },
    {
      title: "Download Link",
      dataIndex: "filePath",
      key: "filePath",
      width: 200,
      render: (text, record) =>
        record?.status === "Processed" ? (
          <a href={text}>
            <Icon name="download" />
          </a>
        ) : null,
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="excelexport-requested-list"
      title="Excel Export Requested"
      columns={columns}
      extra={{
        tableScrollX: 1300,
      }}
    />
  );
};

export default ExcelExportRequestedList;
