import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../../layout/content/Content";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  BlockDes,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../../components/Component";
import Swal from "sweetalert2";
import materialsApis from "../../../api/master/materials";
import MaterialsMaster from "./MaterialMaster";
import { useNavigate } from "react-router-dom";
import FormatDate from "../DateFormater";
import Export from "../../../components/export/Export";
import { useSelector } from "react-redux";
import { Flex, Spin } from "antd";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import dayjs from "dayjs";
const Materials: FC = () => {
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);

  const handleDelete = (materialID: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteProductById(materialID);
      }
    });
  };
  const deleteProductById = async (id: any) => {
    try {
      const { status, message } = await materialsApis.deleteProductById(id);
      if (status) {
        Swal.fire("Deleted!", message, "success");
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Error", error.message, "error");
    } finally {
      setRefreshToggle(!refreshToggle);
    }
  };
  const [filterSupplyCategories, setfilterSupplyCategories] = useState<any>([]);

  const addMaterial = () => {
    navigate(`/addMaterials`);
  };

  useEffect(() => {
    fetchSupplyCategoriesOptions();
  }, []);

  const fetchSupplyCategoriesOptions = async () => {
    try {
      const res = await materialsApis.fetchCategoryFilteredInProduct();
      console.log("status");
      const { data, status, message } = res;
      if (status) {
        let dateresp = data.map((item: any) => {
          return {
            label: item.categoryName,
            value: item.categoryId,
          };
        });
        setfilterSupplyCategories(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: "Material Code",
      dataIndex: "productCode",
      key: "productCode",
      width: 200,
    },
    {
      title: "Material Type",
      dataIndex: "materialName",
      key: "materialName",
      width: 250,
    },
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 250,
    },
    {
      title: "Material Name",
      dataIndex: "productName",
      key: "productName",
      width: 250,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "unitOfMeasureName",
      key: "unitOfMeasureName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 250,
      render: (text: any, record: any) => (
        <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 200,
      render: (text: any, record: any) => (
        <span
          className={`tb-status text-${
            record.status === "Active" ? "success" : "danger"
          }`}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      width: 100,
      render: (text: any, record: any) => (
        <ul className="nk-tb-actions">
          <li
            className="nk-tb-actions-toggle"
            onClick={() =>
              navigate(
                `${process.env.PUBLIC_URL}/vewMaterialDetails/${record.materialID}`
              )
            }
          >
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + record.id}
              icon="focus"
              direction="top"
              text="View Details"
              iconClass={undefined}
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/vewMaterialDetails/${record.materialID}`}
                    >
                      <Icon name="focus"></Icon>
                      <span>View Details</span>
                    </Link>
                  </li>
                  {allowedActions?.cancel ? (
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#edit"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDelete(record.materialID);
                        }}
                      >
                        <Icon name="trash"></Icon>
                        <span>Delete</span>
                      </DropdownItem>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="material-list"
      title="Materials"
      columns={columns}
      extra={{
        // tableScrollX: 1700,
        excelExport: true,
      }}
      selectFilters={[
        {
          title: "Material Categories",
          options: filterSupplyCategories,
        },
      ]}
      refresh={refreshToggle}
      topRightJSX={
        allowedActions?.add ? (
          <Button color="primary" onClick={() => addMaterial()}>
            <Icon name="plus"></Icon>
            <span>Add </span>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );

  // return (
  //   <React.Fragment>
  //     <Head title="Materials"></Head>
  //     <Content>
  //       <BlockHead size="sm">
  //         <BlockBetween>
  //           <BlockHeadContent>
  //             <BlockTitle tag="h3" page>
  //               Materials
  //             </BlockTitle>
  //             <BlockDes className="text-soft">
  //               <p>You have total {rowData.length} materials.</p>
  //             </BlockDes>
  //           </BlockHeadContent>
  //           <BlockHeadContent>
  //             <div className="toggle-wrap nk-block-tools-toggle">
  //               <Button
  //                 className={`btn-icon btn-trigger toggle-expand me-n1 ${
  //                   sm ? "active" : ""
  //                 }`}
  //                 onClick={() => setSm(!sm)}
  //               >
  //                 <Icon name="menu-alt-r"></Icon>
  //               </Button>
  //               <div
  //                 className="toggle-expand-content"
  //                 style={{ display: sm ? "block" : "none" }}
  //               >
  //                 <ul className="nk-block-tools g-3">
  //                   <li>
  //                     <Export data={rowData} />
  //                   </li>
  //                   {allowedActions?.add?<li className="nk-block-tools-opt">
  //                     <Button
  //                       color="primary"
  //                       onClick={() => {
  //                         addMaterial();
  //                       }}
  //                     >
  //                       <Icon name="plus"></Icon>
  //                       <span>Add </span>
  //                     </Button>
  //                   </li>:<></>}
  //                 </ul>
  //               </div>
  //             </div>
  //           </BlockHeadContent>
  //         </BlockBetween>
  //       </BlockHead>
  //       <Block>
  //         <DataTable
  //           className="card-stretch"
  //           bodyClassName={undefined}
  //           title={undefined}
  //         >
  //           <div className="card-inner position-relative card-tools-toggle">
  //             <div
  //               className={`card-search search-wrap active`}
  //             >
  //               <div className="card-body">
  //                 <div className="search-content" style={{ whiteSpace: "nowrap", display: "flex" }}>
  //                 <Button
  //                     className="search-back btn-icon toggle-search active"
  //                   >
  //                     <Icon name="search"></Icon>
  //                   </Button>
  //                   <input
  //                     type="text"
  //                     className="border-transparent form-focus-none form-control"
  //                     placeholder="Search"
  //                     value={onSearchText}
  //                     onChange={(e) => onFilterChange(e)}
  //                   />
  //                    <UncontrolledDropdown>
  //                     <DropdownToggle
  //                       tag="a"
  //                       className="btn btn-trigger btn-icon dropdown-toggle"
  //                     >
  //                       <Icon name="filter"></Icon>
  //                     </DropdownToggle>
  //                     <DropdownMenu end className="dropdown-menu-xs">
  //                       <ul className="link-check">
  //                         <li>
  //                           <span>Show</span>
  //                         </li>
  //                         <li className={itemPerPage === 10 ? "active" : ""}>
  //                           <DropdownItem
  //                             tag="a"
  //                             href="#dropdownitem"
  //                             onClick={(ev) => {
  //                               ev.preventDefault();
  //                               setItemPerPage(10);
  //                             }}
  //                           >
  //                             10
  //                           </DropdownItem>
  //                         </li>
  //                         <li className={itemPerPage === 15 ? "active" : ""}>
  //                           <DropdownItem
  //                             tag="a"
  //                             href="#dropdownitem"
  //                             onClick={(ev) => {
  //                               ev.preventDefault();
  //                               setItemPerPage(15);
  //                             }}
  //                           >
  //                             15
  //                           </DropdownItem>
  //                         </li>
  //                       </ul>
  //                       <ul className="link-check">
  //                         <li>
  //                           <span>Order</span>
  //                         </li>
  //                         <li className={sort === "dsc" ? "active" : ""}>
  //                           <DropdownItem
  //                             tag="a"
  //                             href="#dropdownitem"
  //                             onClick={(ev) => {
  //                               ev.preventDefault();
  //                               setSortState("dsc");
  //                               sortFunc("dsc");
  //                             }}
  //                           >
  //                             DESC
  //                           </DropdownItem>
  //                         </li>
  //                         <li className={sort === "asc" ? "active" : ""}>
  //                           <DropdownItem
  //                             tag="a"
  //                             href="#dropdownitem"
  //                             onClick={(ev) => {
  //                               ev.preventDefault();
  //                               setSortState("asc");
  //                               sortFunc("asc");
  //                             }}
  //                           >
  //                             ASC
  //                           </DropdownItem>
  //                         </li>
  //                       </ul>
  //                     </DropdownMenu>
  //                   </UncontrolledDropdown>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <DataTableBody compact className={undefined} bodyclass={undefined}>
  //             <DataTableHead>
  //               {/* <DataTableRow className={undefined} size={undefined}>
  //                 <span className="sub-text">#</span>
  //               </DataTableRow> */}
  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">Material Code</span>
  //               </DataTableRow>
  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">Material Type</span>
  //               </DataTableRow>
  //               <DataTableRow size="sm" className={undefined}>
  //                 <span className="sub-text">Material Category</span>
  //               </DataTableRow>
  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">Material Name</span>
  //               </DataTableRow>
  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">Specification</span>
  //               </DataTableRow>
  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">UOM</span>
  //               </DataTableRow>
  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">Created Date</span>
  //               </DataTableRow>

  //               <DataTableRow size="md" className={undefined}>
  //                 <span className="sub-text">Status</span>
  //               </DataTableRow>
  //               <DataTableRow size="md" className={undefined}></DataTableRow>
  //             </DataTableHead>
  //             {currentItems.length > 0
  //               ? currentItems.map((item: any, index: number) => {
  //                   return (
  //                     <DataTableItem
  //                       key={item.id}
  //                       className={undefined}
  //                       style={{ color: "black" }}
  //                     >
  //                       {/* <DataTableRow size="md" className={undefined}>
  //                         <span>{index + 1}</span>
  //                       </DataTableRow> */}
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span>{item.productCode}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span>{item.materialName}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="sm" className={undefined}>
  //                         <span>{item.categoryName}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span>{item.productName}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span>{item.specification}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span>{item.unitOfMeasureName}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span>{FormatDate(item.createdDate)}</span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <span
  //                           className={`tb-status text-${
  //                             item.status === "Active" ? "success" : "danger"
  //                           }`}
  //                         >
  //                           {item.status}
  //                         </span>
  //                       </DataTableRow>
  //                       <DataTableRow size="md" className={undefined}>
  //                         <ul className="nk-tb-actions">
  //                           <li
  //                             className="nk-tb-action-hidden"
  //                             onClick={() =>
  //                               navigate(
  //                                 `${process.env.PUBLIC_URL}/vewMaterialDetails/${item.materialID}`
  //                               )
  //                             }
  //                           >
  //                             <TooltipComponent
  //                               tag="a"
  //                               containerClassName="btn btn-trigger btn-icon"
  //                               id={"view" + item.id}
  //                               icon="focus"
  //                               direction="top"
  //                               text="View Details"
  //                               iconClass={undefined}
  //                             />
  //                           </li>
  //                           <li>
  //                             <UncontrolledDropdown>
  //                               <DropdownToggle
  //                                 tag="a"
  //                                 className="dropdown-toggle btn btn-icon btn-trigger"
  //                               >
  //                                 <Icon name="more-h"></Icon>
  //                               </DropdownToggle>
  //                               <DropdownMenu end>
  //                                 <ul className="link-list-opt no-bdr">
  //                                   <li>
  //                                     <Link
  //                                       to={`${process.env.PUBLIC_URL}/vewMaterialDetails/${item.materialID}`}
  //                                     >
  //                                       <Icon name="focus"></Icon>
  //                                       <span>View Details</span>
  //                                     </Link>
  //                                   </li>
  //                                   {allowedActions?.cancel?<li>
  //                                     <DropdownItem
  //                                       tag="a"
  //                                       href="#edit"
  //                                       onClick={(ev) => {
  //                                         ev.preventDefault();
  //                                         handleDelete(item.materialID);
  //                                       }}
  //                                     >
  //                                       <Icon name="trash"></Icon>
  //                                       <span>Delete</span>
  //                                     </DropdownItem>
  //                                   </li>:<></>}
  //                                 </ul>
  //                               </DropdownMenu>
  //                             </UncontrolledDropdown>
  //                           </li>
  //                         </ul>
  //                       </DataTableRow>
  //                     </DataTableItem>
  //                   );
  //                 })
  //               : null}
  //           </DataTableBody>
  //           {isLoading === true ? (
  //             <>
  //               <Flex align="center" gap="middle" className="pageLoader">
  //                 <Spin size="large" className="m-auto" />
  //               </Flex>
  //             </>
  //           ) : (
  //           <div className="card-inner">
  //             {currentItems.length > 0 ? (
  //               <PaginationComponent
  //                 itemPerPage={itemPerPage}
  //                 totalItems={rowData.length}
  //                 paginate={paginate}
  //                 currentPage={currentPage}
  //               />
  //             ) : (
  //               <div className="text-center">
  //                 <span className="text-silent">No data found</span>
  //               </div>
  //             )}
  //           </div>
  //           )}
  //         </DataTable>
  //       </Block>
  //     </Content>
  //     <Modal
  //       isOpen={isOpen}
  //       toggle={() => setIsOpen(false)}
  //       className="modal-dialog-centered"
  //       size="lg"
  //     >
  //       <a
  //         href="#cancel"
  //         onClick={(ev) => {
  //           ev.preventDefault();
  //           setIsOpen(false);
  //         }}
  //         className="close"
  //       >
  //         <Icon name="cross-sm"></Icon>
  //       </a>

  //       <ModalBody>{popUpContent}</ModalBody>
  //     </Modal>
  //   </React.Fragment>
  // );
};

export default Materials;
