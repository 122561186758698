import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import adminOrgApis from "../../api/master/adminOrg";
import dayjs from "dayjs";
import materialsApis from "../../api/master/materials";
import supplierApis from "../../api/master/supplier";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

interface SelectOptions {
  label: string;
  value: any;
}
interface bomLine {
  categoryId: string;
  categoryName: string;
  materialId: string;
  materialName: string;
  quantity: string;
  uomId: string;
  uomName: string;
  allocated: string;
}

interface FormData {
  budgetLineName: string;
  budgetValue: string;
  startDate: any;
  endDate: any;
  bom: string;
  allocation: string;
  lineDescription: string;
  currencyCode: string;
  bomLines: bomLine[];
}
const MaterialModal: FC<any> = ({
  modal,
  closeModal,
  appenddata,
  update,
  formData,
  status,
  budgetStartDate,
  budgetEndDate,
  materialCategoryOptions,
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialData, setMaterialData] = useState<any>();
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

  const methods = useForm<FormData>({
    defaultValues: {
      budgetLineName: "",
      budgetValue: "",
      startDate: "",
      endDate: "",
      bom: "",
      allocation: "",
      lineDescription: "",
      currencyCode: "",
      bomLines: [],
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "bomLines",
  });

  const startDateWatch = watch("startDate");

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const billOfMaterialWatch = watch("bom");

  const addNewDocument = () => {
    // if (fields.length < 10) {
    append({
      categoryId: "",
      categoryName: "",
      materialId: "",
      materialName: "",
      uomId: "",
      uomName: "",
      quantity: "",
      allocated: "",
    });
    // }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (modal && formData.item) {
      reset({
        budgetLineName: formData.item.budgetLineName,
        budgetValue: new Intl.NumberFormat("en-IN", {}).format(
          formData?.item?.budgetValue
        ), // Remove commas and non-breaking space before parsing to number
        startDate: dayjs(formData.item.startDate),
        endDate: dayjs(formData.item.endDate),
        bom: formData.item.bom,
        currencyCode: formData.item.currencyCode,
        allocation: formData.item.allocation,
        lineDescription: formData.item.lineDescription,
        bomLines: formData.item.bomLines.map((bomLine: any) => ({
          ...bomLine,
          allocated:
            formData.item.allocation == "value"
              ? bomLine.allocated
                ? new Intl.NumberFormat("en-IN", {}).format(bomLine.allocated)
                : ""
              : bomLine.allocated,
          quantity: bomLine.quantity
            ? bomLine.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "", // Format the quantity with commas
        })),
      });
    } else {
      reset({
        budgetLineName: "",
        budgetValue: "",
        startDate: "",
        endDate: "",
        bom: "",
        allocation: "",
        lineDescription: "",
        currencyCode: "",
        bomLines: [],
      });
    }
  }, [formData.item, modal, reset]);

  useEffect(() => {
    console.log("billOfMaterialWatch", billOfMaterialWatch);
    if (billOfMaterialWatch === "Y" && fields.length > 0) {
      (async () => {
        const { data, materialOption }: any = await fetchMaterials(
          fields[fields.length - 1]?.categoryId,
          fields.length - 1
        );
        await fetchUom(
          fields[fields.length - 1]?.materialId,
          data,
          fields.length - 1
        );
      })();
    }
  }, [billOfMaterialWatch, append, fields.length]);

  const fetchUom = async (materialId: any, materialData: any, index: any) => {
    if (!materialId) return [];
    try {
      const materialUom = materialData?.find(
        (item: any) => item.materialId == materialId
      );
      if (!materialUom) return;
      const _uomOptions = [
        {
          value: materialUom?.primaryUomId,
          label: materialUom?.PrimaryUomName,
        },
      ];
      if (
        materialUom?.secondaryUomId &&
        materialUom?.primaryUomId != materialUom?.secondaryUomId
      ) {
        _uomOptions.push({
          value: materialUom?.secondaryUomId,
          label: materialUom?.secondaryUomName,
        });
      }
      setUomOptions(_uomOptions);
      return _uomOptions;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async (categoryId: any, index: any) => {
    if (!categoryId) return { data: [], materialOption: [] };
    try {
      const { data, status } = await supplierApis.fetchCategoryProducts(
        categoryId
      );
      if (status) {
        const materialOption = data.map((item: any) => ({
          label: item?.materialName,
          value: item?.materialId?.toString(),
        }));
        setMaterialData(data);
        setMaterialOptions(materialOption);
        return {
          data,
          materialOption,
        };
      }
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const submitForm = (dataForm: FormData) => {
    try {
      const budgetValue = parseFloat(dataForm.budgetValue.replace(/,/g, ""));
      const totalAllocated = dataForm.bomLines.reduce((sum: any, item: any) => {
        return (
          sum +
          parseFloat(item?.allocated?.toString()?.replace(/,/g, "") || "0")
        );
      }, 0);
      if (dataForm.allocation === "value") {
        if (dataForm.bom === "Y" && totalAllocated > budgetValue) {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Total Allocated value in BOM Lines cannot exceed the Budget Value.",
          });
          return;
        }
      } else if (dataForm.allocation === "percentage") {
        const totalPercentage = totalAllocated;
        if (dataForm.bom === "Y" && totalPercentage > 100) {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Total Allocated % in BOM Lines cannot exceed the more than 100%.",
          });
          return;
        }
      }
      // Extract the existing data for the current item being edited
      const existingData = formData.item || {};

      // Merge existing data with the updated form data
      const updatedData = {
        ...existingData,
        ...dataForm,
      };

      if (updatedData.bom === "N") {
        updatedData.bomLines = [];
        delete updatedData.allocation;
      }
      delete updatedData.boms;
      // updatedData.boms = updatedData.bomLines;
      // delete updatedData.bomLines;
      if (updatedData.bomLines.length > 0) {
        updatedData.bomLines = updatedData.bomLines.map((bom: any) => {
          bom.quantity = bom.quantity;
          bom.allocated = parseFloat(
            bom?.allocated?.toString()?.replace(/,/g, "") || "0"
          );
          bom.uomId = Number(bom.uomId);
          return bom;
        });
      }
      updatedData.budgetValue =
        parseFloat(updatedData.budgetValue.replace(/,/g, "")) || 0;

      updatedData.startDate = dayjs(updatedData.startDate).format("YYYY-MM-DD");
      updatedData.endDate = dayjs(updatedData.endDate).format("YYYY-MM-DD");
      updatedData.currencyCode = "INR";
      console.log("Updated Data", updatedData);
      if (formData.action == "add" || !formData.index == null) {
        appenddata({
          ...updatedData,
          lineId: null,
        });
      } else {
        update(formData.index, {
          ...updatedData,
          lineId: formData?.item?.lineId,
        });
      }

      closeModal();
    } catch (error) {
      console.log("Error While Submitting or Updating Line Details", error);
    }
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Budget Line"
            : "Update Budget Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="gy-4 mt-2">
              <Col lg="6">
                <FormInput
                  name="budgetLineName"
                  label="Budget Line Name"
                  required={true}
                  placeholder="Enter Budget Line Name"
                />
              </Col>

              <Col lg="6">
                <FormInput
                  min={1}
                  type="text" // Use 'text' type to allow formatting like 1,000
                  name="budgetValue"
                  label="Budget Value"
                  required={true}
                  placeholder="Enter Budget Value"
                  pattern={/^[0-9,]+$/} // Allow only digits (0-9) and commas
                  onInput={(e: any) => {
                    const val = e.target.value.replace(/[^\d.]/g, ""); // Remove all non-digit and non-decimal characters
                    if (val.length > 0 && !/^[0-9]+(\.[0-9]+)?$/.test(val)) {
                      e.target.value = val.replace(/[^\d.]/g, ""); // Remove non-digit and non-decimal characters
                    } else {
                      const n = Number(val);
                      const formattedValue = isNaN(n)
                        ? ""
                        : n.toLocaleString("en-IN"); // Format with Indian numbering system
                      e.target.value = formattedValue;
                    }
                  }}
                />
              </Col>
              <Col lg="6">
                <FormDatePicker
                  name="startDate"
                  label="Planned Start Date"
                  placeholder="Select Start Date"
                  required={true}
                  minDate={new Date(budgetStartDate)}
                  maxDate={new Date(budgetEndDate)}
                />
              </Col>
              <Col lg="6">
                <FormDatePicker
                  name="endDate"
                  label="Planned End Date"
                  placeholder="Select End Date"
                  required={true}
                  minDate={startDateWatch}
                  maxDate={new Date(budgetEndDate)}
                />
              </Col>
              <Col lg="6">
                <FormSelect
                  name="bom"
                  label="Bill Of Material (BOM)"
                  options={[
                    { label: "Yes", value: "Y" },
                    { label: "No", value: "N" },
                  ]}
                  required={true}
                  placeholder="Select BOM"
                  onChange={(e) => {
                    console.log("e.target.value", e);
                    if (e === "Y" && fields.length === 0) {
                      append({
                        categoryId: "",
                        categoryName: "",
                        materialId: "",
                        materialName: "",
                        uomId: "",
                        uomName: "",
                        quantity: "",
                        allocated: "",
                      });
                    } else if (e === "N") {
                      reset({
                        ...methods.getValues(),
                        bomLines: [],
                      });
                    }
                  }}
                />
              </Col>
              {billOfMaterialWatch === "Y" && (
                <Col lg="6">
                  <FormSelect
                    disabled={
                      formData.action !== "add" && formData.item.allocation
                    }
                    name="allocation"
                    label="BOM Allocation"
                    options={[
                      { label: "Value", value: "value" },
                      { label: "%", value: "percentage" },
                    ]}
                    required={true}
                    placeholder="Select BOM Allocation"
                  />
                </Col>
              )}
              <Col lg="12">
                <FormTextArea
                  name="lineDescription"
                  label="Budget Line Description"
                  required={true}
                  placeholder="Enter Budget Line Description"
                />
              </Col>
            </Row>

            {billOfMaterialWatch === "Y" && (
              <Row className="gy-4 mt-2">
                {fields.map((field, index, fieldArray) => {
                  return (
                    <FormProvider key={field.id} {...methods}>
                      <form>
                        <div
                          className="mt-2 d-flex align-items-center justify-content-around"
                          key={field.id}
                        >
                          <Row
                            style={{
                              width: "50%",
                              display:
                                index < fieldArray.length - 1 ? "none" : "",
                            }}
                          >
                            <Col lg="4">
                              <FormSelect
                                name={`bomLines.${index}.categoryId`}
                                label="Category"
                                options={materialCategoryOptions}
                                required={true}
                                onChange={(value) => {
                                  const categoryName =
                                    materialCategoryOptions.find(
                                      (category: any) => category.value == value
                                    )?.label || value;
                                  setValue(
                                    `bomLines.${index}.categoryName`,
                                    categoryName
                                  );
                                  fetchMaterials(value, index);
                                }}
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name={`bomLines.${index}.materialId`}
                                label="Material Name"
                                options={materialOptions}
                                required={true}
                                onChange={(value) => {
                                  const materialName =
                                    materialOptions.find(
                                      (material: any) => material.value == value
                                    )?.label || value;
                                  setValue(
                                    `bomLines.${index}.materialName`,
                                    materialName
                                  );
                                  fetchUom(value, materialData, index);
                                }}
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name={`bomLines.${index}.uomId`}
                                label="UOM"
                                options={uomOptions}
                                required={true}
                                onChange={(value) => {
                                  const uomName =
                                    uomOptions.find(
                                      (uom: any) => uom.value == value
                                    )?.label || value;
                                  setValue(
                                    `bomLines.${index}.uomName`,
                                    uomName
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                          <Row
                            style={{
                              width: "50%",
                              display:
                                fieldArray.length == index + 1 ? "none" : "",
                            }}
                          >
                            <Col lg="4">
                              <FormInput
                                name={`bomLines.${index}.categoryName`}
                                label="Category"
                                required={true}
                                disabled
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name={`bomLines.${index}.materialName`}
                                label="Material Name"
                                required={true}
                                disabled
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name={`bomLines.${index}.uomName`}
                                label="UOM"
                                required={true}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row style={{ width: "50%" }}>
                            <Col lg="4">
                              <FormInput
                                min={1}
                                name={`bomLines.${index}.quantity`}
                                label="Qty"
                                type="number"
                                required={true}
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                min={1}
                                name={`bomLines.${index}.allocated`}
                                label="Allocation"
                                type="text"
                                required={true}
                                pattern={/^[0-9,]+$/} // Allow only digits (0-9) and commas
                                onInput={(e: any) => {
                                  const val = e.target.value.replace(
                                    /[^\d.]/g,
                                    ""
                                  ); // Remove all non-digit and non-decimal characters
                                  if (
                                    val.length > 0 &&
                                    !/^[0-9]+(\.[0-9]+)?$/.test(val)
                                  ) {
                                    e.target.value = val.replace(/[^\d.]/g, ""); // Remove non-digit and non-decimal characters
                                  } else {
                                    const n = Number(val);
                                    const formattedValue = isNaN(n)
                                      ? ""
                                      : n.toLocaleString("en-IN"); // Format with Indian numbering system
                                    e.target.value = formattedValue;
                                  }
                                }}
                              />
                            </Col>

                            <Col lg="4" className="d-flex align-items-center">
                              <a
                                onClick={() => {
                                  try {
                                    remove(index);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                                className="danger"
                                style={{
                                  fontSize: 16,
                                  color: "red",
                                  marginTop: 14,
                                  cursor: "pointer",
                                }}
                              >
                                <MinusCircleOutlined /> Remove
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </form>
                    </FormProvider>
                  );
                })}
                <Row className="mt-2">
                  <Col lg="12">
                    <a
                      onClick={() => {
                        handleSubmit(
                          (data) => {
                            addNewDocument(); // Add new row after successful submission
                          },
                          (err) => {
                            console.log("error occured", err);
                          }
                        )();
                      }}
                      className="primary"
                      style={{ fontSize: 16, cursor: "pointer" }}
                    >
                      <PlusCircleOutlined /> Add More
                    </a>
                  </Col>
                </Row>
              </Row>
            )}
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action === "edit" && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  {formData.action == "add" && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default MaterialModal;
