import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Row, Col, Alert } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { Icon, Button, TooltipComponent } from "../../../components/Component";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import { SelectOptions } from "./types"; // Import your types
import supplierApis from "../../../api/master/supplier";
import { Popover } from "antd";
import { nanoid } from "nanoid";

const AddNewMaterial: FC<any> = ({
  modal,
  closeModal,
  append,
  update,
  formData,
  needByDateType,
  deliveryAddressLevel,
  materialCategoryOptions,
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);
  const [materialData, setMaterialData] = useState<any>();
  const [dmsFile, setDmsFile] = useState<any>(null);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const files = watch("attachment");

  useEffect(() => {
    if (files && files[0]?.size && files[0].size > 2097152) {
      setValue("attachment", null);
      setAlertInfo({
        type: "error",
        message: "File size should be less than 2 MB",
        errors: [],
      });
    }
  }, [files]);

  const deliveryAddressId = watch("deliveryAddressId");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAlertInfo({
        type: "",
        message: "",
        errors: [],
      });
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, [alertInfo]);

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status, message } =
        await deliveryAddressApis.fetchDeliveryAddress();

      if (status) {
        let deliveryAddressOptions = data.map((item: any) => {
          return {
            label: item.deliveryLocationName,
            value: item.id.toString(),
          };
        });
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      } else {
        console.log(message);
      }
    } catch (error) {}
  };
    const fetchUom = async (materialId: any, materialData: any,uomId: any) => {
    try {
      const materialUom = materialData?.find(
        (item: any) => item.materialId == materialId
      );
      if (!materialUom) return;
      const _uomOptions = [
        {
          value: materialUom?.primaryUomId,
          label: materialUom?.PrimaryUomName,
        },
      ];
      if (
        materialUom?.secondaryUomId &&
        materialUom?.primaryUomId != materialUom?.secondaryUomId
      ) {
        _uomOptions.push({
          value: materialUom?.secondaryUomId,
          label: materialUom?.secondaryUomName,
        });
      }
      setValue("uomId", uomId?uomId:materialUom?.primaryUomId);
      setUomOptions(_uomOptions);
    } catch (error) {
      console.log(error);
    }
  };

   const fetchMaterials = async (categoryId: any, materialId: any) => {
    try {
      const { data, status } = await supplierApis.fetchCategoryProducts(
        categoryId
      );
      if (status) {
        const materialOption = data.map((item: any) => ({
          label: item?.materialName,
          value: item?.materialId?.toString(),
        }));
        setMaterialOptions(materialOption);
        setMaterialData(data);
        setValue("materialName", materialId);
        return data
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    if (modal && formData.item) {
      const {
        materialCategory,
        materialName,
        specification,
        quantity,
        uomId,
        deliveryAddressId,
        description,
        needByDate,
        file,
        dms,
      } = formData.item;
      console.log(formData.item);
      setValue("materialCategory", materialCategory);
      (async () => {
        const _materialData = await fetchMaterials(materialCategory, materialName);
        fetchUom(materialName, _materialData,uomId);
      })();
      setValue("quantity", quantity);
      setValue("specification",specification);
      setValue("description", description);
      setValue("attachment", file);
      if (formData.index != null) {
        setValue("deliveryAddressId", deliveryAddressId);
        setValue("needByDate", needByDate);
      }
      if (dms?.length > 0) {
        setDmsFile(dms[0]);
      }
    }
  }, [modal]);

  const submitForm = (data: any) => {
    const {
      materialCategory,
      materialName,
      quantity,
      specification,
      description,
      uomId,
      needByDate,
      attachment,
      deliveryAddressId,
    } = data;
    console.log("data", data);
    const item = {
      materialName,
      quantity,
      specification,
      description,
    };
    const materialLabel = materialOptions.find(
      (item) => item.value == materialName
    )?.label;
    const categoryLabel = materialCategoryOptions?.find(
      (item:any) => item.value == materialCategory
    )?.label;
    const uomLabel = uomOptions.find((item) => item.value == uomId)?.label;

    if (formData.action == "add" || formData.index == null) {
      append({
        key: nanoid(),
        materialCategory,
        categoryLabel,
        materialName,
        materialLabel,
        quantity,
        specification,
        uomId,
        uomLabel,
        description,
        needByDate,
        attachment: attachment?.length ? true : false,
        file: attachment,
        deliveryAddressId,
      });
    } else {
      update(formData.index, {
        ...formData.item,
        key: nanoid(),
        materialCategory,
        categoryLabel,
        materialName,
        materialLabel,
        quantity,
        specification,
        uomId,
        uomLabel,
        description,
        needByDate,
        attachment: attachment?.length ? true : false,
        file: attachment,
        deliveryAddressId,
        dms: dmsFile ? formData?.item?.dms : [],
      });
    }
    closeModal();
  };

  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }
    let address = options?.find((item: any) => item[matchKey] == id);
    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
              {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };
  useEffect(() => {
    fetchDeliveryAddress();
  }, []);

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl" backdrop="static">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Line"
            : "Update Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="mt-12">
              <div className="col-md-6">
                <FormSelect
                  name="materialCategory"
                  label="Material Category"
                  required={true}
                  options={materialCategoryOptions}
                  placeholder="Select Material Category"
                  onChange={(e:any) => {
                    fetchMaterials(e,null);
                  }}
                />
              </div>
              <div className="col-md-6">
                <FormSelect
                  name="materialName"
                  label="Material Name"
                  required={true}
                  options={materialOptions}
                  placeholder="Select Material Name"
                  onChange={(e:any)=>{
                    fetchUom(e, materialData,null);
                  }}
                /> 
              </div>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="specification"
                  label="Specification"
                  placeholder="Enter Specification"
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  type="number"
                  min={1}
                  required={true}
                  name="quantity"
                  label="Quantity"
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="col-md-3">
                <FormSelect
                  name="uomId"
                  label="UOM"
                  required={true}
                  options={uomOptions}
                  placeholder="Units"
                />
              </div>
              </Row>
              <div style={{ marginTop: "15px" }}></div>
              <Row>
              {needByDateType === "LINE_LEVEL" && (
                <div className="col-md-6">
                  <FormDatePicker
                    name="needByDate"
                    label="Need By Date"
                    required={true}
                    minDate={new Date()}
                  />
                </div>
              )}
              <Col md={6} className="mt-2">
                {((files && files[0]?.name) ||
                  dmsFile ||
                  dmsFile?.fileName) && (
                  <div className="form-group m-0">
                    <div className="form-label-wrapper">
                      <label className="form-label" htmlFor={"attachment"}>
                        Attachment (If any)
                      </label>
                      <TooltipComponent
                        iconClass="card-hint"
                        icon="help-fill"
                        direction="left"
                        id={`file-tooltip`}
                        text="e.g: Design specificaitons"
                        containerClassName={""}
                      />
                    </div>
                    <div
                      className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                      style={{ borderRadius: "3px" }}
                    >
                      <span>
                        {(files && files[0]?.name) || dmsFile?.fileName}
                      </span>
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                          setDmsFile(null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: `${
                      (files && files[0]?.name) || dmsFile?.fileName
                        ? "none"
                        : "block"
                    }`,
                  }}
                >
                  <FormInput
                    type="file"
                    name="attachment"
                    label="Attachment (If any) "
                    placeholder="Enter Attachment"
                    tooltipText="e.g: Design specificaitons"
                  />
                </div>
              </Col>

              {deliveryAddressLevel === "LINE_LEVEL" && (
                <>
                  <div className="col-md-6">
                    <FormSelect
                      name="deliveryAddressId"
                      label="Delivery Address"
                      required={true}
                      options={deliveryAddressOptions}
                      popOverOptions={
                        <ShowAddressPopOver
                          id={deliveryAddressId}
                          matchKey="id"
                          options={deliveryAddressList}
                          text="Address"
                        />
                      }
                      placeholder="Select Delivery Address"
                      tooltipText="Select the delivery address for the purchase order."
                    />
                  </div>
                </>
              )}
            </Row>
            {/* <Row className="mt-4">
              <div className="col-md-12">
                <FormTextArea
                  name="description"
                  label="Description (if any)"
                  placeholder="Enter Description"
                  minLength={5}
                  maxLength={200}
                />
              </div>
            </Row> */}
            <Row className="mt-4">
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      <span>Submit</span>
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AddNewMaterial;
