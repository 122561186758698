import axios from "axios";
import authService from "./auth/authService";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_API_LOGIN_URL;
console.log(API_URL);

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data && config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (!navigator.onLine) {
      await new Promise((resolve) => setTimeout(resolve, 15000));
      return axiosInstance(originalRequest);
    }
    if (originalRequest.url == "/auth/refresh") {
      return;
    }
    if (
      error.response.status === 401 &&
      error?.response?.data?.message === "Token Expired"
    ) {
      try {
        const data = await authService.refreshToken();
        if (data.status && data.token && data.token.access_token) {
          Cookies.set("access-token", JSON.stringify(data.token), {
            expires: 7,
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("refresh-token", data.token.refresh_token, {
            expires: 7,
            secure: true,
            sameSite: "Strict",
          });
          const accessToken = Cookies.get("access-token");
          originalRequest.headers["Authorization"] = `${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        Swal.fire({
          title: "Session Expired",
          text: "Re-login to continue",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.href = "/";
          }
        });
        await new Promise((resolve) => setTimeout(resolve, 10000));
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // Clear service worker cache
        if ("caches" in window) {
          caches.keys().then((keyList) => {
            return Promise.all(keyList.map((key) => caches.delete(key)));
          });
        }

        // Clear IndexedDB (optional, if used)
        if (window.indexedDB) {
          indexedDB.databases().then((databases) => {
            databases.forEach((db: any) => indexedDB.deleteDatabase(db.name));
          });
        }

        window.location.href = "/";
        // Perform a forced page reload (Ctrl + Shift + R equivalent)
        window.location.reload(); // Forces a reload ignoring cached content
      }
    } else if (
      error.response.status === 401 &&
      window.location.pathname !== "/"
    ) {
      console.log("Unauthorized access");
      window.location.href = "/";
      return error?.response?.data;
    } else if (error.response.status === 409) {
      return error.response;
    } else if (error.response.status === 400) {
      return error.response;
    } else if (error.response.status === 404) {
      return error.response;
    }
    console.log("------ Error ---------", error?.response?.data);
    throw error?.response?.data;
  }
);

export default axiosInstance;
