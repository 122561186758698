import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";

interface SearchInputProps {
  name: string;
  placeholder?: string;
  width?: number | string;
  prefixIcon?: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  onDebouncedSearch?: (value: string) => void;
  debounceDelay?: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
  name,
  placeholder = "Search...",
  width = 400,
  prefixIcon = <SearchOutlined />,
  style = {},
  disabled = false,
  required = false,
  defaultValue = "",
  onDebouncedSearch,
  debounceDelay = 500, // Default delay for debounce
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const errorMessage = getErrorMessage(errors, name);

  // Debounced function to handle API calls
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (onDebouncedSearch) {
        onDebouncedSearch(value);
      }
    }, debounceDelay),
    [onDebouncedSearch, debounceDelay]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(name, value); // Update react-hook-form value
    debouncedSearch(value); // Trigger the debounced function
  };

  return (
    <div style={{ display: "flex", alignItems: "center", ...style }}>
      <div style={{ width }}>
        <Input
          id={name}
          placeholder={placeholder}
          prefix={prefixIcon}
          disabled={disabled}
          onChange={handleChange}
          defaultValue={defaultValue}
          autoComplete="off"
        />
        {errorMessage && (
          <p style={{ color: "red", marginTop: 4, fontSize: "0.875em" }}>
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

function getErrorMessage<T>(
  errors: Record<string, any>,
  fieldName: string
): string | undefined {
  const parts = fieldName.split(/\[|\].?|\./).filter(Boolean);
  let currentError: any = errors;

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default SearchInput;

