import makeApiCall from "../master/axiosIndex";
const creditDebitNoteApis = {
  creditDebitNoteCreationMethod: (data: any): Promise<any> =>
    makeApiCall({
      url: "/credit-debit-note/submitCreditDebitNote",
      method: "post",
      data,
    }),
  fetchAllCreditDebitNotes: (): Promise<any> =>
    makeApiCall({
      url: "/credit-debit-note/fetchAllCreditDebitNotes",
      method: "get",
    }),
  fetchAllUserConfirmedCreditDebitNotes: (): Promise<any> =>
    makeApiCall({
      url: `/credit-debit-note/fetchAllUserConfirmedCreditDebitNotes`,
      method: "get",
    }),
  fetchAllUserPendingConfirmationCreditDebitNotes: (): Promise<any> =>
    makeApiCall({
      url: `/credit-debit-note/fetchAllUserPendingConfirmationCreditDebitNotes`,
      method: "get",
    }),
  fetchCreditDebitNote: (noteNumber: any): Promise<any> =>
    makeApiCall({
      url: `/credit-debit-note/fetchCreditDebitNote?noteNumber=${noteNumber}`,
      method: "get",
    }),
  confirmCreditDebitNote: (noteNumber: any): Promise<any> =>
    makeApiCall({
      url: `/credit-debit-note/confirmCreditDebitNote?noteNumber=${noteNumber}`,
      method: "patch",
    }),
  rejectCreditDebitNote: (
    noteNumber: any,
    rejectedReason: string
  ): Promise<any> =>
    makeApiCall({
      url: `/credit-debit-note/rejectCreditDebitNote?noteNumber=${noteNumber}&rejectedReason=${rejectedReason}`,
      method: "patch",
    }),
  fetchAllCreditDebitNotesWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/credit-debit-note/fetchAllCreditDebitNotesWithPagination",
      method: "get",
      params,
    }),
  fetchAllUserPendingConfirmationCreditDebitNotesWithPagination: (
    params: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: "/credit-debit-note/fetchAllUserPendingConfirmationCreditDebitNotesWithPagination",
      method: "get",
      params,
    }),
};

export default creditDebitNoteApis;
