import React, { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  TooltipComponent,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import {
  Progress,
  Table,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
} from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import quotationApis from "../../api/master/quotations";
import Swal from "sweetalert2";
import moment from "moment";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
}
const RFQApprovals: FC = () => {
  const [sm, setSm] = useState(false);

  const navigate = useNavigate();

  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate(`/view/${rfq.quotationId}`, {
      state: { screenStatus: "Pending RFQ Approvals" },
    });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 170,
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleRFQClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      // fixed: "left",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`quotationDescription-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`quotationDescription-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
      align: "left",
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Cost Center",
      dataIndex: "costCenterName",
      key: "costCenterName",
      width: 150,
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // fixed: "right",
      render: (text, record) => (
        <span style={{ color: record.statusColor }}>{text}</span>
      ),
      width: 150,
      align: "left",
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="rfq-approvals"
      title="Pending RFQ Approvals"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <QuotationsAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Status",
          placeholder: "Select Status",
          options: [],
        },
      ]}
      extra={{
        tableScrollX: 1100,
      }}
    />
  );
};

export default RFQApprovals;
