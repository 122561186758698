import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { Icon } from "../../../components/Component";
import { useNavigate } from "react-router";
function MaterialUtilizationReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Site",
      key: "siteName",
      dataIndex: "siteName",
      width: 100,
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
    },

    {
      title: "Issue Date",
      key: "issueDate",
      dataIndex: "issueDate",
      width: 100,
      render: (text: string) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Issued By",
      key: "issuedBy",
      dataIndex: "issuedBy",
      width: 100,
    },
    {
      title: "Utilized Date",
      key: "utilizedDate",
      dataIndex: "utilizedDate",
      width: 100,
      render: (text: any) => {
       return text ? dayjs(text).format("DD-MMM-YYYY") : "N/A";
      },
    },
    {
      title: "Utilized By",
      key: "utilizedBy",
      dataIndex: "utilizedBy",
      width: 100,
    },
    {
      title: "QTY",
      key: "utilizedQuantity",
      dataIndex: "utilizedQuantity",
      width: 100,
    },
    {
      title: "UOM",
      key: "uomName",
      dataIndex: "uomName",
      width: 100,
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   // fixed: "right",
    //   width: 150,
    //   render: (text: any, record: any, index: any) => (
    //     <>
    //       <Button
    //         size="sm"
    //         color=""
    //         onClick={() => {
    //           navigate(
    //             `/view-material-issue-details/${record.materialIssueId}`
    //           );
    //         }}
    //       >
    //         <Icon name="focus" />
    //       </Button>
    //     </>
    //   ),
    // },
  ];
  return (
    <ReportTemplate2
      pageTitle="	Material Utilization"
      moduleKey="inventoryMaterialUtilizationReportList"
      columns={columns}
      filtersOption={{
        site: true,
        // contractor: true,
        // dateRange: true,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default MaterialUtilizationReport;
