import React, { useState, useEffect, FC, useMemo, useRef } from "react";
import Content from "../../../layout/content/Content";
import { Card, Row, Col } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "../../../components/Component";
import MastersAside from "../MastersAside";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Swal from "sweetalert2";
import DynamicSidebar from "../../../components/dynamicSidebar/DynamicSidebar";
import SupplierMaster from "./SupplierMaster";
import { Supplier } from "./types/Supplier";
import SupplierInfo from "./SupplierInfo";
import supplierApis from "../../../api/master/supplier";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Export from "../../../components/export/Export";
import { Flex, Spin } from "antd";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import { render } from "@testing-library/react";
import { ColumnGroupType, ColumnType } from "antd/es/table";
const Suppliers: FC = () => {
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [modal, setModal] = useState<any>({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState<Supplier[]>([]);
  const [originalData, setOriginalData] = useState([]);

  const [filterSupplyCategories, setfilterSupplyCategories] = useState<any>([]);

  const handleDelete = (SupplierID: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSupplier(SupplierID);
      }
    });
  };

  const deleteSupplier = async (id: any) => {
    try {
      const { status, message } = await supplierApis.deleteSupplierById(id);
      if (status) {
        Swal.fire("Deleted!", "Supplier has been deleted", "success");
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response.data.message, "error");
    } finally {
      setRefreshToggle(!refreshToggle);
    }
  };

  const addSupplier = () => {
    navigate(`/addSuppliers`);
  };

  const columns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: "Supplier ID",
      dataIndex: "referenceID",
      key: "referenceID",
      width: 200,
      fixed: "left",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 25 ? `${text?.substring(0, 22)}... ` : text}
            </span>
            {text?.length > 25 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Supply Categories",
      dataIndex: "category",
      key: "category",
      width: 300,
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
      width: 250,
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
      width: 250,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "State",
      dataIndex: "stateName",
      key: "stateName",
      width: 200,
      render: (text: any, record: any) => record.state.stateName,
    },
    {
      title: "District",
      dataIndex: "districtName",
      key: "districtName",
      width: 200,
      render: (text: any, record: any) => record.district.districtName,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "igst",
      width: 200,
      render: (text: any, record: any) => (
        <span
          className={`tb-status text-${
            record.status === "Active" ? "success" : "danger"
          }`}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 100,
      render: (text: any, record: any) => (
        <ul className="nk-tb-actions">
          <li
            className="nk-tb-actions-toggle"
            onClick={() =>
              navigate(
                `${process.env.PUBLIC_URL}/vewSuppliersDetails/${record.supplierID}`
              )
            }
          >
             {/* <Icon name="focus"></Icon> */}
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"edit" + record.id}
              icon="focus"
              direction="top"
              text="View Details"
              iconClass={undefined}
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  {/* <li onClick={() => onEditClick(item.id)}>
                                     <DropdownItem
                                       tag="a"
                                       href="#edit"
                                       onClick={(ev) => {
                                         ev.preventDefault();
                                       }}
                                     >
                                       <Icon name="edit"></Icon>
                                       <span>Edit</span>
                                     </DropdownItem>
                                   </li> */}
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/vewSuppliersDetails/${record.supplierID}`}
                    >
                      <Icon name="focus"></Icon>
                      <span>View Details</span>
                    </Link>
                  </li>
                  {allowedActions?.cancel ? (
                    <li onClick={() => handleDelete(record.supplierID)}>
                      <Link to={`#`}>
                        <Icon name="delete"></Icon>
                        <span>Delete</span>
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    fetchSupplyCategoriesOptions();
  }, []);

  const fetchSupplyCategoriesOptions = async () => {
    try {
      const res = await supplierApis.fetchCategoryFilteredInSupplier();
      console.log("status");
      const { data, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = data.map((item: any) => {
          return {
            label: item.categoryName,
            value: item.categoryId,
          };
        });
        console.log("dateresp", dateresp);
        setfilterSupplyCategories(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="suppliers-list"
      title="Suppliers"
      columns={columns}
      extra={{
        tableScrollX: 2500,
        excelExport: true,
      }}
      selectFilters={[
        {
          title: "Supply Categories",
          options: filterSupplyCategories,
        },
      ]}
      refresh={refreshToggle}
      topRightJSX={
        allowedActions?.add ? (
          <Button color="primary" onClick={() => addSupplier()}>
            <Icon name="plus"></Icon>
            <span>Add </span>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};

export default Suppliers;
