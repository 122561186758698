import makeApiCall from "../master/axiosIndex";
const miscService = {
  createLoginActivity: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/misc/createLoginActivity",
      method: "post",
      data,
    });
  },
  fetchLoginActivity: (): Promise<any> => {
    return makeApiCall({
      url: "/misc/fetchLoginActivity",
      method: "get",
    });
  },
  fetchNotificationSettings: (): Promise<any> => {
    return makeApiCall({
      url: "/misc/fetchNotificationSettings",
      method: "get",
    });
  },
  updateNotificationSettings: (data: any): Promise<any> => {
    return makeApiCall({
      url: `/misc/updateNotificationSettings`,
      method: "put",
      data,
    });
  },
  getByQuery: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/dms/getByQuery",
      method: "post",
      data,
    });
  },
  fetchEmailConfigurations: (): Promise<any> => {
    return makeApiCall({
      url: "/misc/fetchEmailConfigurations",
      method: "get",
    });
  },
  createEmailConfigurations: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/misc/createEmailConfigurations",
      method: "post",
      data,
    });
  },
  fetchExcelExportRequestedListWithPagination: (params: any): Promise<any> => {
    return makeApiCall({
      url: "/order-vs-billing/excelExportRequestedListWithPagination",
      method: "get",
      params,
    });
  },
  excelExportRequest: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/order-vs-billing/excelExportRequest",
      method: "post",
      data,
    });
  },
};

export default miscService;
