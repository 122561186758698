import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Label } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import InvoiceAside from "./InvoiceAside";
import invoiceApis from "../../api/Invoice/invoice";
import statusMapper from "./StatusMapper";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const ViewInvoiceList: FC = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => <span>{FormatDate(date)}</span>,
      width: 170,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "GRN Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
      render(value: any, record: any, index: any) {
        const grnStatus: any = {
          FullyDelivered: "Fully Delivered",
          PartiallyDelivered: "Partially Delivered",
        };
        return <p className="tb-status">{grnStatus[value] || value}</p>;
      },
    },
    {
      title: "Payment Days",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 150,
      render: (text: any, record: any) => {
        const paymentStatus: any = {
          FullyPaid: "FULLY PAID",
          PartiallyPaid: "PARTIALLY PAID",
        };
        if (text == "Pending") {
          const { days } = record?.due;
          if (days <= 0) {
            return (
              <p
                style={{ color: "blue", fontSize: "14px" }}
                className="tb-status"
              >
                {" "}
                DUE IN {days * -1} DAYS
              </p>
            );
          }
          return (
            <p className="text-danger"> OVERDUE BY {record?.due?.days} DAYS</p>
          );
        }
        return (
          <p style={{ color: "green", fontSize: "14px" }} className="tb-status">
            {paymentStatus[text] || text}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      width: 180,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.seller;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "View",
      key: "actions",
      fixed: "right",
      width: 80,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view-invoice/${record.invoiceNumber}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-invoice-list"
      title="Invoices"
      columns={columns}
      selectFilters={[
        {
          title: "Invoice Status",
          placeholder: "Select Status",
          options: [
            {
              label: "Pending Approval",
              value: "Pending Approval",
            },
            {
              label: "Approval Rejected",
              value: "Approval Rejected",
            },
            {
              label: "Partially Delivered",
              value: "Partially Delivered",
            },
            {
              label: "Fully Delivered",
              value: "Fully Delivered",
            },
            {
              label: "Partially Dispatched",
              value: "Partially Dispatched",
            },
            {
              label: "Fully Dispatched",
              value: "Fully Dispatched",
            },
            {
              label: "To be Dispatched",
              value: "To be Dispatched",
            },
          ],
        },
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        excelExport: true,
        dataFilterTootip: "Filtering based on Invoice date",
      }}
    />
  );
};

export default ViewInvoiceList;
