import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../../layout/content/Content";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  BlockDes,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../../components/Component";
import Swal from "sweetalert2";
import usersApis from "../../../api/master/users";
import UsersMaster from "./UsersMaster";
import { useNavigate } from "react-router-dom";
import FormatDate from "../DateFormater";
import Export from "../../../components/export/Export";
import { useSelector } from "react-redux";
import { Flex, Spin } from "antd";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import dayjs from "dayjs";
const Users: FC = () => {
  const [refreshToggle, setRefreshToggle] = useState(false);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBuyerById(id);
      }
    });
  };
  const deleteBuyerById = async (id: any) => {
    try {
      const { status, message } = await usersApis.deleteBuyerById(id);
      if (status) {
        Swal.fire("Deleted!", "Buyer has been deleted.", "success");
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response.data.message, "error");
    } finally {
      setRefreshToggle(!refreshToggle);
    }
  };

  const navigate = useNavigate();

  const createUser = () => {
    navigate(`/createUsers`);
  };

  const columns = [
    {
      title: "Internal ID",
      dataIndex: "referenceID",
      key: "referenceID",
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      width: 250,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
      width: 250,
    },
    {
      title: "Reporting Manager",
      dataIndex: "reportingManagerName",
      key: "reportingManagerName",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 250,
      render: (text: any, record: any) => (
        <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 200,
      render: (text: any, record: any) => (
        <span
          className={`tb-status text-${
            record.status === "Active" ? "success" : "danger"
          }`}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      width: 100,
      render: (text: any, record: any) => (
        <ul className="nk-tb-actions">
          <li
            className="nk-tb-actions-toggle"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/userProfile/${record.userID}`)
            }
          >
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"edit" + record.id}
              icon="focus"
              direction="top"
              text="View Details"
              iconClass={undefined}
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  {/* <li onClick={() => {}}>
                                         <DropdownItem
                                           tag="a"
                                           href="#edit"
                                           onClick={(ev) => {
                                             ev.preventDefault();
                                             handleModelPopUp(item.id, "edit");
                                           }}
                                         >
                                           <Icon name="edit"></Icon>
                                           <span>Edit</span>
                                         </DropdownItem>
                                       </li> */}
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/userProfile/${record.userID}`}
                    >
                      <Icon name="focus"></Icon>
                      <span>View Details</span>
                    </Link>
                  </li>
                  {allowedActions?.edit ? (
                    <li onClick={() => handleDelete(record.userID)}>
                      <Link to={`#`}>
                        <Icon name="trash"></Icon>
                        <span>Delete user</span>
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="users-list"
        title="Users"
        columns={columns}
        extra={{
          tableScrollX: 1700,
        }}
        refresh={refreshToggle}
        topRightJSX={
          allowedActions?.add ? (
            <Button color="primary" onClick={() => createUser()}>
              <Icon name="plus"></Icon>
              <span>Add </span>
            </Button>
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default Users;
