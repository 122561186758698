import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { formatCurrency } from "../../../utils/Utils";
import { useNavigate } from "react-router";

function MaterialConsumptionReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Site Name",
      key: "siteName",
      dataIndex: "siteName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 150,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 150,
    },
    {
      title: "BOQ QTY",
      key: "boqQty",
      dataIndex: "boqQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Avg Target Value",
      key: "avgTargetValue",
      dataIndex: "avgTargetValue",
      width: 150,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), "INR")}
        </div>
      ),
    },
    {
      title: "Utilized QTY",
      key: "utilizedQty",
      dataIndex: "utilizedQty",
      width: 100,
      render: (text: any, record: any) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }

        return (
          <div style={{ textAlign: "right" }}>
            {value === 0 ? (
              <span>{value.toFixed(2)}</span> // Render plain text for 0.00
            ) : (
              <span
                className="inlineTableCellLink"
                onClick={() => {
                  navigate(`/material-consumption-detail-report`, {
                    state: {
                      materialName: record?.materialName,
                      materialId: record?.MaterialId,
                      categoryId: record?.categoryId,
                      siteId: record?.siteId,
                    },
                  });
                }}
              >
                {value.toFixed(2)}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Avg utilized Value",
      key: "avgUtilizedValue",
      dataIndex: "avgUtilizedValue",
      width: 150,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {text !== null ? formatCurrency(text?.toString(), "INR") : "N/A"}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 150,
      render: (text: string) => (
        <span style={{ color: text === "On Track" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Material Consumption Summary"
      moduleKey={window.location.pathname}
      columns={columns}
      filtersOption={{
        site: true,
        category: true,
        material: true,
      }}
      previousPath="/view-budget-reports"
    />
  );
}

export default MaterialConsumptionReport;
