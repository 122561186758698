import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody,Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon} from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import { Popover } from "antd";
import adminOrgApis from "../../../api/master/adminOrg";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import { nanoid } from "nanoid";
import materialsApis from "../../../api/master/materials";
import supplierApis from "../../../api/master/supplier";
interface SelectOptions {
  label: string;
  value: any;
}

const MaterialModal: FC<any> = ({
  modal,
  closeModal,
  append,
  update,
  formData,
  taxConfiguration,
  needByDateType,
  deliveryAddressType,
   materialCategoryOptions,
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialData, setMaterialData] = useState<any>();
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

  
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const files = watch("attachment");

  useEffect(() => {
    if (files && files[0]?.size && files[0].size > 2097152) {
      setValue("attachment", null);
      setAlertInfo({
        type: "error",
        message: "File size should be less than 2 MB",
        errors: [],
      });
    }
  }, [files]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAlertInfo({
        type: "",
        message: "",
        errors: [],
      });
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, [alertInfo]);

  

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchUom = async (materialId: any, materialData: any,uomId: any) => {
    try {
      const materialUom = materialData?.find(
        (item: any) => item.materialId == materialId
      );
      if (!materialUom) return;
      const _uomOptions = [
        {
          value: materialUom?.primaryUomId,
          label: materialUom?.PrimaryUomName,
        },
      ];
      if (
        materialUom?.secondaryUomId &&
        materialUom?.primaryUomId != materialUom?.secondaryUomId
      ) {
        _uomOptions.push({
          value: materialUom?.secondaryUomId,
          label: materialUom?.secondaryUomName,
        });
      }
      setValue("uomId", uomId?uomId:materialUom?.primaryUomId);
      setUomOptions(_uomOptions);
    } catch (error) {
      console.log(error);
    }
  };

   const fetchMaterials = async (categoryId: any, materialId: any) => {
    try {
      const { data, status } = await supplierApis.fetchCategoryProducts(
        categoryId
      );
      if (status) {
        const materialOption = data.map((item: any) => ({
          label: item?.materialName,
          value: item?.materialId?.toString(),
        }));
        setMaterialOptions(materialOption);
        setMaterialData(data);
        setValue("materialName", materialId);
        return data
      }
    } catch (error) {
      console.log(error);
    }
  }; 



  useEffect(() => {
    if (modal && formData.item) {
      const {
        materialCategory,
        materialName,
        quantity,
        specification,
        uomId,
        remarks,
        description,
      } = formData.item;
      console.log(formData.item);
      setValue("materialCategory", materialCategory);
      (async () => {
        const _materialData = await fetchMaterials(materialCategory, materialName);
        fetchUom(materialName, _materialData,uomId);
      })(); 
      setValue("quantity", quantity);
      setValue("specification",specification);
      setValue("remarks", remarks);
      setValue("description", description);
    }
  }, [modal]);

  const submitForm = (data: any) => {
    const {
      materialCategory,
      materialName,
      hsnCode,
      quantity,
      specification,
      remarks,
      description,
      uomId,
    } = data;
    const item = {
      materialCategory,
      materialName,
      hsnCode,
      quantity,
      specification,
      remarks,
      description,
    };

    const materialLabel = materialOptions.find(
      (item) => item.value == materialName
    )?.label;

    const categoryLabel = materialCategoryOptions.find(
      (item:any) => item.value == materialCategory
    )?.label;
    const uomLabel = uomOptions.find((item) => item.value == uomId)?.label;

    if (formData.action == "add" || formData.index == null) {
      append({
        key: nanoid(),
        materialCategory,
        categoryLabel,
        materialName,
        materialLabel,
        hsnCode,
        quantity,
        specification,
        remarks,
        uomId,
        uomLabel,
        description,
      });
    } else {
      update(formData.index, {
        key: nanoid(),
        materialCategory,
        categoryLabel,
        materialName,
        materialLabel,
        hsnCode,
        quantity,
        specification,
        remarks,
        uomId,
        uomLabel,
        description,
      });
    }
    closeModal();
  };
 

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Line"
            : "Update Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="mt-12">
              <div className="col-md-6">
                <FormSelect
                  name="materialCategory"
                  label="Material Category"
                  required={true}
                  options={materialCategoryOptions}
                  placeholder="Select Material Category"
                  onChange={(e:any) => {
                    fetchMaterials(e,null);
                  }}
                />
              </div>
              <div className="col-md-6">
                <FormSelect
                  name="materialName"
                  label="Material Name"
                  required={true}
                  options={materialOptions} 
                  placeholder="Select Material Name"
                  onChange={(e:any)=>{
                    fetchUom(e, materialData,null);
                  }}
                />
              </div>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <div className="col-md-3">
                <FormInput
                  type="number"
                  min={1}
                  required={true}
                  name="quantity"
                  label="Required Quantity"
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="col-md-3">
                <FormSelect
                  name="uomId"
                  label="UOM"
                  required={true}
                  options={uomOptions}
                  placeholder="Units"
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="specification"
                  label="Specification"
                  placeholder="Enter Specification"
                />
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-md-6">
                <FormInput
                  type="text"
                  required={false}
                  min={1}
                  name="remarks"
                  label="Remarks (if any)"
                  placeholder="Enter Remarks"
                />
              </div>
              {/* <div className="col-md-12">
                                <FormTextArea
                                    name="description"
                                    required={false}
                                    label="Description (if any)"
                                    placeholder="Enter Description"
                                    minLength={5}
                                    maxLength={200}
                                />
                            </div> */}
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action === "edit" && formData.index != null && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  {(formData.action == "add" || formData.index == null) && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default MaterialModal;
