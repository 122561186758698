import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Card } from "reactstrap";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { DatePicker, Empty, Flex, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import contractorsApis from "../../api/master/contractors";
import dayjs from "dayjs";
import reportApis from "../../api/reports";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "../form-components/SelectComponent";
import supplierApis from "../../api/master/supplier";
import { formatCurrency } from "../../utils/Utils";
import Export from "../../components/export/Export";
import { useDispatch } from "react-redux";
import { updateDefaultReportTemplateState } from "../../store/slices/misc/defaultReportTemplate";
const { RangePicker } = DatePicker;

//  This template has site, contractor, supplier, dateRange, customDateRange, aging, intervalOfDays filters with table pagination

function DefaultReportTemplate({
  pageTitle,
  moduleKey,
  columns,
  filtersOption,
  previousPath,
  extra,
}: {
  pageTitle: string;
  moduleKey: string;
  columns: any;
  filtersOption: {
    site?: boolean;
    contractor?: boolean;
    dateRange?: boolean;
    supplier?: boolean;
    aging?: boolean;
  };
  previousPath: string;
  extra?: {
    id?: any;
    tableScrollX?: number;
    customData?: any;
  };
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const { data: GlobalData }: any = useSelector(
    (state: RootState) => state.defaultReportTemplate
  );
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const [supplierOptions, setSupplierOptions] = useState<any>([]);
  const [contractorOptions, setContractorOptions] = useState<any>([]);
  const [customDateRange, setCustomDateRange] = useState<any>();
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "40", "50"],
  });

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const supplier = watch("supplier");
  const site = watch("site");
  const contractor = watch("contractor");
  const dateRange = watch("dateRange");
  const aging = watch("aging");
  const intervalOfDays = watch("intervalOfDays");

  const fetchContractorList = async (siteId: any) => {
    try {
      if (
        !filtersOption.contractor ||
        !siteId ||
        siteId?.toString()?.includes(",")
      ) {
        setValue("contractor", "");
        setContractorOptions([]);
        return;
      }
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId + "",
      });
      if (status) {
        let allContractors: any = [];
        let _contractorOptions: any = [];
        data.forEach((item: any) => {
          allContractors.push(item?.contractorID?.toString());
          _contractorOptions.push({
            label: item?.companyName,
            value: item?.contractorID?.toString(),
          });
        });

        setValue("contractor", null);
        if (_contractorOptions.length > 1) {
          _contractorOptions = [
            {
              label: "ALL",
              value: allContractors?.join(","),
            },
            ..._contractorOptions,
          ];
        }
        setContractorOptions(_contractorOptions);
        formatParamsForFetchData({
          aging,
          intervalOfDays,
          site,
          supplier,
          contractor: null,
          dateRange,
          customDateRange,
          pagination: {
            current: 1,
            pageSize: pagination?.pageSize,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (params: any) => {
    try {
      let response: any = {};
      switch (moduleKey) {
        case "purchaseOrdersReport":
          response = await reportApis.purchaseOrdersReport(params);
          break;
        case "purchaseBySupplier":
          response = await reportApis.purchaseBySupplierReport(params);
          break;
        case "purchaseBySupplierDetailReport":
          response = await reportApis.purchaseBySupplierDetailReport({
            ...GlobalData?.purchaseBySupplier,
            supplierId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "purchaseByMaterialReport":
          response = await reportApis.purchaseByMaterialReport(params);
          break;
        case "purchaseByMaterialDetailReport":
          response = await reportApis.purchaseByMaterialDetailReport({
            ...GlobalData?.purchaseByMaterialReport,
            materialId: extra?.id,
            uomId: extra?.customData?.uomId + "",
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "purchaseByCategoryReport":
          response = await reportApis.purchaseByCategoryReport(params);
          break;
        case "purchaseByCategoryDetailReport":
          response = await reportApis.purchaseByCategoryReportDetailReport({
            ...GlobalData?.purchaseByCategoryReport,
            categoryId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "purchaseBySiteReport":
          response = await reportApis.purchaseBySiteReport(params);
          break;
        case "purchaseBySiteDetailReport":
          response = await reportApis.purchaseBySiteDetailReport({
            ...GlobalData?.purchaseBySiteReport,
            siteId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "invoiceBySupplierReport":
          response = await reportApis.invoiceBySupplierReport(params);
          break;
        case "deliveryNoteDetailsReport":
          response = await reportApis.deliveryNoteDetailsReport(params);
          break;
        case "materialsByDeliveryNoteReport":
          response = await reportApis.materialsByDeliveryNoteReport({
            deliveryNoteId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;

        case "supplierPaymentsReport":
          response = await reportApis.supplierPaymentsReport(params);
          break;
        case "supplierPaymentsInvoiceReport":
          response = await reportApis.supplierPaymentsInvoiceReport({
            ...GlobalData?.supplierPaymentsReport,
            supplierId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "paymentsAgingSummaryReport":
          response = await reportApis.paymentsAgingSummaryReport(params);
          break;
        case "paymentsAgingSummaryInvoiceReport":
          response = await reportApis.paymentsAgingSummaryInvoiceReport({
            ...GlobalData?.paymentsAgingSummaryReport,
            supplierId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "paymentsAgingReport":
          response = await reportApis.paymentsAgingReport(params);
          break;
        case "paymentsReport":
          response = await reportApis.paymentsReport(params);
          break;
        case "invoiceDetailsForPaymentsReport":
          response = await reportApis.invoiceDetailsForPaymentsReport({
            transactionNumber: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;
        case "supplierBalancesReport":
          response = await reportApis.supplierBalancesReport(params);
          break;
        case "supplierBalancesDetailReport":
          response = await reportApis.supplierBalancesDetailReport({
            ...GlobalData?.supplierBalancesReport,
            supplierId: extra?.id,
            page: params.page,
            pageSize: params.pageSize,
          });
          break;

        default:
          alert("Invalid Module Key");
      }
      const { response: _response, status }: any = response;
      if (status) {
        setDataSource(_response?.data);
        setPagination((pre: any) => ({
          ...pre,
          current: _response?.pagination?.currentPage,
          pageSize: _response?.pagination?.pageSize,
          total: Number(_response?.pagination?.totalItems),
        }));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const convertToDates = (dateType: string) => {
    try {
      switch (dateType) {
        case "Month to Date":
          return {
            from: dayjs().startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Quarter to Date":
          return {
            from: dayjs()
              .subtract(3, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Year to Date":
          return {
            from: dayjs().startOf("year").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        default:
          if (!customDateRange)
            return {
              from: dayjs()?.format("YYYY-MM-DD"),
              to: dayjs()?.format("YYYY-MM-DD"),
            };
          return {
            from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
            to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
          };
      }
    } catch (error) {
      return {
        from: dayjs()?.format("YYYY-MM-DD"),
        to: dayjs()?.format("YYYY-MM-DD"),
      };
    }
  };
  const formatParamsForFetchData = ({
    supplier,
    site,
    contractor,
    dateRange,
    customDateRange,
    pagination,
    aging,
    intervalOfDays,
  }: any) => {
    if (site) {
      if (
        (dateRange == "Custom Date" && !customDateRange) ||
        // (filtersOption?.contractor && !contractor) ||                       // commented for now
        (filtersOption?.supplier && !supplier) ||
        (filtersOption?.site && !site) ||
        (filtersOption?.aging && !aging) ||
        (filtersOption?.aging && !intervalOfDays)
      )
        return;
      setLoading(true);
      const { from, to } = convertToDates(dateRange);
      let paramsObj: any = {};
      if (filtersOption?.site) paramsObj.siteId = site + "";
      if (filtersOption?.supplier) paramsObj.supplierId = supplier;
      // if (filtersOption?.contractor)
      paramsObj.contractorId = contractor;
      if (filtersOption?.aging) paramsObj.interval = aging;
      if (filtersOption?.aging) paramsObj.intervalDays = intervalOfDays;
      const newParamsObj = {
        ...paramsObj,
        startDate: from,
        endDate: to,
        page: pagination.current,
        pageSize: pagination.pageSize,
      };
      fetchData(newParamsObj);
      dispatch(
        updateDefaultReportTemplateState({
          [moduleKey]: newParamsObj,
        })
      );
    } else if (!filtersOption?.site) {
      setLoading(true);
      fetchData({
        page: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  };
  useEffect(() => {
    formatParamsForFetchData({
      aging,
      intervalOfDays,
      site,
      supplier,
      contractor,
      dateRange,
      customDateRange,
      pagination: {
        current: 1,
        pageSize: pagination?.pageSize,
      },
    });
  }, [supplier, contractor, dateRange, customDateRange, aging, intervalOfDays]);

  useEffect(() => {
    if (site) {
      fetchContractorList("" + site);
    }
  }, [site]);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth >= 990) {
      setSm(false);
    }
  };

  const fetchSupplierFilter = async () => {
    try {
      if (!filtersOption.supplier) return;
      const { data, status } = await supplierApis.fetchSupplierFilter();
      if (status) {
        let _supplierOptions: any = [];
        let allSuppliers: any = [];
        data.forEach((item: any) => {
          allSuppliers.push(item?.supplierOrgID?.toString());
          _supplierOptions.push({
            label: item?.supplierName,
            value: item?.supplierOrgID?.toString(),
          });
        });
        setValue("supplier", allSuppliers?.join(","));
        if (_supplierOptions.length > 1) {
          _supplierOptions = [
            {
              label: "ALL",
              value: allSuppliers?.join(","),
            },
            ..._supplierOptions,
          ];
        }
        setSupplierOptions(_supplierOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    viewChange();
    fetchSupplierFilter();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility && filtersOption?.site) {
        const allSites: any = [];
        let _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setValue("site", allSites?.join(","));
        if (_siteOptions?.length > 1) {
          _siteOptions = [
            {
              label: "ALL",
              value: allSites?.join(","),
            },
            ..._siteOptions,
          ];
        }
        setSiteOptions(_siteOptions);
      }
      if (filtersOption?.dateRange) {
        setValue("dateRange", "Quarter to Date");
      }
      if (filtersOption?.aging) {
        setValue("aging", 3);
        setValue("intervalOfDays", 15);
      }
    }
  }, [currentUser]);

  const getAgingIntervalColumns = () => {
    if (aging == 1) {
      return [
        {
          title: ">= 1 days",
          dataIndex: ">= 1 days",
          key: ">= 1 days",
          render: (obj: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              {obj?.Amount ? (
                <span>
                  {formatCurrency(
                    obj?.Amount?.toString(),
                    record?.currencyCode
                  )}
                  {" ("}
                  {obj?.Percentage}
                  {")"}
                </span>
              ) : (
                "N/A"
              )}
            </div>
          ),
        },
      ];
    } else {
      let newColumns: any = [];
      let i = 0,
        intervalCount = 0;
      for (i = 0; i < aging - 1; i++) {
        newColumns.push({
          title: `${intervalCount + 1}-${intervalCount + intervalOfDays} days`,
          dataIndex: `${intervalCount + 1}-${
            intervalCount + intervalOfDays
          } days`,
          key: `${intervalCount + 1}-${intervalCount + intervalOfDays} days`,
          render: (obj: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              {obj?.Amount ? (
                <span>
                  {formatCurrency(
                    obj?.Amount?.toString(),
                    record?.currencyCode
                  )}
                  {" ("}
                  {obj?.Percentage}
                  {")"}
                </span>
              ) : (
                "N/A"
              )}
            </div>
          ),
        });
        intervalCount += intervalOfDays;
      }
      newColumns.push({
        title: `> ${intervalCount} days`,
        dataIndex: `>= ${intervalCount + 1} days`,
        key: `>= ${intervalCount + 1} days`,
        render: (obj: any, record: any) => (
          <div style={{ textAlign: "right" }}>
            {obj?.Amount ? (
              <span>
                {formatCurrency(obj?.Amount?.toString(), record?.currencyCode)}
                {" ("}
                {obj?.Percentage}
                {")"}
              </span>
            ) : (
              "N/A"
            )}
          </div>
        ),
      });
      return newColumns;
    }
  };

  return (
    <>
      <Head title="View All Reports" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className="card-inner card-inner-lg"
              style={{ minHeight: "75vh" }}
            >
              {sm && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>{pageTitle}</span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <div className="actions clearfix">
                    <ul
                      className="m-auto d-flex align-items-center justify-content-center p-0"
                      style={{
                        flexDirection: "row-reverse",
                      }}
                    >
                      <li>
                        <Export
                          data={{
                            supplierId: supplier,
                            contractorId: contractor,
                            dateRange: dateRange && convertToDates(dateRange),
                            customDateRange,
                            aging,
                            intervalOfDays,
                            siteId: site,
                            page: pagination.current,
                            pageSize: pagination.pageSize,
                          }}
                          moduleKey={moduleKey}
                          reportType="Reports"
                        />
                      </li>
                      <li className="pt-0 pb-0">
                        <Button
                          className="toggle"
                          color="primary"
                          size="sm"
                          onClick={async () => {
                            navigate(previousPath);
                          }}
                        >
                          <Icon name="arrow-left" />
                          <span>Back</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </BlockBetween>
              </BlockHead>
              {/* content start here */}
              <div className="d-flex align-items-center flex-wrap mb-2">
                <FormProvider {...methods}>
                  <form className="w-100 d-flex align-items-center justify-content-start">
                    {filtersOption?.site && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="site"
                          label="Select Site"
                          required={true}
                          hideRequiredMark={true}
                          placeholder="Select Site"
                          options={siteOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.contractor && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="contractor"
                          // required={true}
                          hideRequiredMark={true}
                          label="Select Contractor"
                          placeholder="Select Contractor"
                          options={contractorOptions}
                        />
                      </div>
                    )}
                    {filtersOption?.supplier && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="supplier"
                          required={true}
                          hideRequiredMark={true}
                          label="Select Supplier"
                          placeholder="Select Supplier"
                          options={supplierOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.dateRange && (
                      <div className="w-50 d-flex align-items-end justify-content-start">
                        <div
                          className="mt-1 mb-0 w-40"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="dateRange"
                            required={true}
                            hideRequiredMark={true}
                            // label=""
                            label={"Select Date Range"}
                            // optionLabel="Date Range"
                            placeholder="Select Date Range"
                            options={[
                              {
                                value: "Month to Date",
                                label: "Month to Date",
                              },
                              {
                                value: "Quarter to Date",
                                label: "Quarter to Date",
                              },
                              { value: "Year to Date", label: "Year to Date" },
                              { value: "Custom Date", label: "Custom Date" },
                            ]}
                          />
                        </div>
                        <div className="mt-1 mb-0 w-50">
                          {dateRange === "Custom Date" && (
                            <RangePicker
                              value={
                                customDateRange
                                  ? [
                                      dayjs(customDateRange[0].toDate()),
                                      dayjs(customDateRange[1].toDate()),
                                    ]
                                  : null
                              }
                              onChange={(dates) => {
                                if (dates && dates[0] && dates[1]) {
                                  setCustomDateRange([dates[0], dates[1]]);
                                } else {
                                  setCustomDateRange(null);
                                }
                              }}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {filtersOption?.aging && (
                      <>
                        <div
                          className="mt-1 mb-0 w-20"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="aging"
                            required={true}
                            hideRequiredMark={true}
                            label="Select Aging"
                            placeholder="Select Aging"
                            options={[
                              { label: "1 Interval", value: 1 },
                              ...Array.from({ length: 11 }, (_, i) => ({
                                label: i + 2 + " Intervals",
                                value: i + 2,
                              })),
                            ]}
                          />
                        </div>
                        <div
                          className="mt-1 mb-0 w-20"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="intervalOfDays"
                            required={true}
                            hideRequiredMark={true}
                            label="Select Interval Of Days"
                            placeholder="Select Interval Of Days"
                            options={[
                              { label: "1 day", value: 1 },
                              ...Array.from({ length: 29 }, (_, i) => ({
                                label: i + 2 + " days",
                                value: i + 2,
                              })),
                            ]}
                          />
                        </div>
                      </>
                    )}
                  </form>
                </FormProvider>
              </div>
              <div className="content clearfix">
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : dataSource?.length > 0 ? (
                  <div
                    style={{
                      width: screenSize - 100 + "px",
                      margin: "0px auto",
                    }}
                  >
                    <Table
                      className="customTable"
                      columns={
                        filtersOption?.aging
                          ? [...columns, ...getAgingIntervalColumns()]
                          : columns
                      }
                      dataSource={
                        filtersOption?.aging
                          ? dataSource?.map((oldObj: any) => {
                              return {
                                ...oldObj,
                                ...oldObj?.agingDetails,
                              };
                            })
                          : dataSource
                      }
                      bordered
                      scroll={{
                        x: filtersOption?.aging
                          ? (extra?.tableScrollX || 0) + aging * 150
                          : extra?.tableScrollX || 1500,
                      }}
                      size="middle"
                      pagination={pagination}
                      onChange={(newPagination, filters, sorter, extra) => {
                        formatParamsForFetchData({
                          aging,
                          intervalOfDays,
                          site,
                          supplier,
                          contractor,
                          dateRange,
                          customDateRange,
                          pagination: {
                            ...pagination,
                            current: newPagination.current,
                            pageSize: newPagination.pageSize,
                          },
                        });
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Data Found " />
                  </div>
                )}
              </div>
            </div>

            {/* content end here */}
          </div>
        </Card>
      </Content>
    </>
  );
}

export default DefaultReportTemplate;
