import React, { useState, useEffect, FC, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  TooltipComponent,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import {
  Progress,
  Table,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Drawer,
  Empty,
  Flex,
  Spin,
  Timeline,
} from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import {
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import quotationApis from "../../api/master/quotations";
import Swal from "sweetalert2";
import moment from "moment";
import UserLabel from "../../utils/UserLabel";
import { formatCurrency, colors } from "../../utils/Utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import dayjs from "dayjs";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface TableData {
  key: number;
  rfqId: string;
  qcId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
  qcStatus: string;
  initialPrice: string;
  price: string;
  tax: string;
}
const QCList: FC = () => {
  const [sm, setSm] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectRecord, setRejectRecord] = useState<any>(null);

  const [qcLines, setQCLines] = useState<any>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const navigate = useNavigate();

  const [navigationQuotationId, setNavigationQuotationId] = useState();

  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedShortListIds, setSelectedShortListIds] = useState<string>("");
  const [originalQCLines, setOriginalQCLines] = useState<any>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<any>([]);
  const [supplierOptions, setSupplierOptions] = useState<any>([]);

  const [quotationId, setquotationId] = useState<any>();

  const supplierColorMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    let colorIndex = 0;

    qcLines.forEach((record: any) => {
      if (!map[record.supplierName]) {
        map[record.supplierName] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });

    return map;
  }, [qcLines]);

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 170,
      align: "left",
      // render: (text, record) => (
      //   <a onClick={() => handleRFQClick(record)}>{text}</a>
      // ),
    },
    {
      title: "QC ID",
      dataIndex: "qcId",
      key: "qcId",
      fixed: "left",
      width: 100,
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleQcClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
      align: "left",
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      // fixed: "left",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`quotationDescription-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`quotationDescription-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Cost Center",
      dataIndex: "costCenterName",
      key: "costCenterName",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`costCenterName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`costCenterName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   // fixed: "right",
    //   render: (text, record) => (
    //     <span style={{ color: record.statusColor }}>{text}</span>
    //   ),
    //   width: 150,
    //   sorter: (a, b) => a.status.localeCompare(b.status),
    //   align: "left",
    // },
  ];

  const columnsLines: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 300,
      render: (text: string, record: any, index: number) => (
        <UserLabel
          supplierName={record.supplierName}
          color={supplierColorMap[record.supplierName]}
          text={text}
        />
      ),
    },
    {
      title: "Material Name",
      fixed: "left",
      dataIndex: "materialName",
      width: 160,
      key: "materialName",
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      width: 160,
      key: "uomName",
    },
    {
      title: "Asked Quantity",
      dataIndex: "requiredQty",
      width: 160,
      key: "requiredQty",
      render: (text) => {
        // Convert the value to a float, apply toFixed(2), then format with commas
        const formattedNumber = parseFloat(text)
          .toFixed(2) // Ensure two decimal places
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format with commas

        return `${formattedNumber}`;
      },
    },
    {
      title: "Awarded Quantity",
      dataIndex: "awardedQty",
      key: "awardedQty",
      width: 160,
      render: (text) => {
        // Convert the value to a float, apply toFixed(2), then format with commas
        const formattedNumber = parseFloat(text)
          .toFixed(2) // Ensure two decimal places
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format with commas

        return `${formattedNumber}`;
      },
    },
    {
      title: "Awarded Qty %",
      dataIndex: "awardedPercentage",
      key: "awardedPercentage",
      width: 160,
      render: (text: any) => `${text.toFixed(2)}%`,
    },
    {
      title: "Initial Quotation",
      dataIndex: "initialPrice",
      key: "initialPrice",
      width: 160,
      render: (text: any, record: any) => <>{formatCurrency(text, "INR")}</>,
    },
    {
      title: "Best Quotation",
      dataIndex: "price",
      key: "price",
      width: 160,
      render: (text: any, record: any) => <>{formatCurrency(text, "INR")}</>,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      width: 160,
      render: (text: any, record: any) => (
        <>
          {formatCurrency(text, "INR")}
          <Popover
            content={
              <div>
                <p>
                  CGST:{" "}
                  {record.cgst == 0 ? "N/A" : record?.cgst + `${"%"}` || "N/A"}
                </p>
                <p>
                  SGST:{" "}
                  {record.sgst == 0 ? "N/A" : record?.sgst + `${"%"}` || "N/A"}
                </p>
                <p>
                  IGST:{" "}
                  {record.igst == 0 ? "N/A" : record.igst`${"%"}` || "N/A"}
                </p>
              </div>
            }
            title="Tax Breakdown"
          >
            <InfoCircleOutlined style={{ marginLeft: 8 }} />
          </Popover>
        </>
      ),
    },
    {
      title: "Line Total",
      dataIndex: "lineTotal",
      key: "lineTotal",
      width: 160,
      render: (text: any, record: any) => <>{formatCurrency(text, "INR")}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 160,
      fixed: "right",
      render: (text: any, record: any) => (
        <Popover
          content={ApprovalDetails(record)} // Render the popover content
          title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
          trigger="hover" // Show popover on hover
        >
          <span
            style={{ color: "#1677ff", fontWeight: "600", cursor: "pointer" }}
          >
            {text}
          </span>
        </Popover>
      ),
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      align: "left",
    },
  ];

  const handleSupplierChange = (value: string | undefined) => {
    console.log("value", value, originalQCLines);
    setSelectedSuppliers(value);
    filterDataBasedOnSuppliers(originalQCLines, value);
  };

  const ApprovalDetails = (record: any) => {
    const approvalsData = record?.approvals;

    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Timeline className="approval-timeline">
        {approvalsData?.map((approval: any, index: number) => {
          const isApproved = approval.status === "APPROVED";
          const isRejected = approval.status === "REJECTED";

          return (
            <Timeline.Item
              key={index}
              dot={
                isApproved ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : isRejected ? (
                  <CloseCircleOutlined style={{ color: "red" }} />
                ) : undefined
              }
            >
              {approval?.userName}
              <div className="designation">{approval?.roleName}</div>
              {approval?.approvalDate && (
                <div className="designation">
                  {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                </div>
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  const handleQcClick = async (record: any) => {
    console.log("record", record);
    setquotationId(record.quotationId);
    setNavigationQuotationId(record.quotationId);
    try {
      const res = await quotationApis.getLinesByQCId(
        record.qcId,
        record.quotationId
      );
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        setOriginalQCLines(response.data.suppliers);
        const supplierOption = response.data.suppliers.map((item: any) => ({
          label: item.supplierName,
          value: item.referencedSupplierId.toString(),
        }));
        setSupplierOptions(supplierOption);
        setSelectedSuppliers(supplierOption[0].value); // Set the first category as default
        filterDataBasedOnSuppliers(
          response.data.suppliers,
          supplierOption[0].value
        );
        setDrawerVisible(true);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const filterDataBasedOnSuppliers = (data: any, supplier: any) => {
    console.log(
      "filteredData",
      data.filter((item: any) => supplier.includes(item.referencedSupplierId))
    );
    setQCLines(data);
    setSelectedSuppliers(supplier);
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    console.log("selectedRows", newSelectedRowKeys, selectedRows);
    setSelectedRowKeys(newSelectedRowKeys); // Update selected row keys
    const shortListIds: any = selectedRows.map((row) => row.id);
    console.log("shortListIds", shortListIds);
    setSelectedShortListIds(shortListIds); // Update supplier IDs as a comma-separated string
  };

  // Row selection configuration
  const rowSelection = {
    selectedRowKeys, // Bind the selected row keys state to row selection
    onChange: onSelectChange, // Handle selection changes
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  // const handleApprove = async (record: any) => {
  //   let data = {
  //     quotationId: record.quotationId,
  //     shortListId: record.shortListId,
  //     reason: "",
  //     id: record.id,
  //   };
  //   try {
  //     const res = await quotationApis.qcApprove(data);
  //     const { response, status, message } = res;
  //     console.log("data", res);
  //     if (status) {
  //       Swal.fire(
  //         "Approved!",
  //         "Quotation Comparision has been Approved",
  //         "success"
  //       );
  //       fetchPendingApprovalList();
  //     } else {
  //       Swal.fire("Failed!", message, "error");
  //     }
  //   } catch (error) {
  //     Swal.fire("Failed!", "Something went wrong", "error");
  //   }
  // };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const PONavigation = async () => {
    console.log("selectedShortListIds", selectedShortListIds);
    let data = {
      quotationId: quotationId,
      supplierId: selectedSuppliers,
      shortListIds: selectedShortListIds,
    };
    console.log("quotationId", data);
    const { response, status } = await quotationApis.convertToPO(data);
    if (status) {
      console.log("response", response.data);
      const data = response.data;
      navigate("/purchase-order-new", { state: { data } });
    }
  };

  // const fetchPendingApprovalList = async () => {
  //   try {
  //     const { response, status, message } = await quotationApis.viewAllQCs();
  //     if (status) {
  //       const data = response.data;

  //       console.log("data", data);
  //       setTableData(data);
  //       setFilteredData(data);
  //     } else {
  //       Swal.fire("Error", message, "error");
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="qc-list"
        title="QC Lines"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <QuotationsAside updateSm={setSm} sm={sm} />
          </div>
        }
        extra={{
          tableScrollX: 1100,
        }}
      />
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>QC Lines</strong>
            {/* <div style={{ textAlign: "right" }}>
                          <Button
                              color="primary"
                              className="m-3 mt-0 mb-0"
                              onClick={() => {
                                  PONavigation();
                              }}
                          >
                              Convert to PO
                          </Button>
                      </div> */}
          </div>
        }
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        width="90vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {/* <>
                  <Row>
                      <Col>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginRight: 8 }}>Filter by:</span>
                              <Select
                                  placeholder="Select Supplier"
                                  style={{ width: 200 }}
                                  value={selectedSuppliers}
                                  onChange={handleSupplierChange}
                                  allowClear
                              >
                                  {supplierOptions?.map((supplier: any) => (
                                      <Option key={supplier.value} value={supplier.value}>
                                          {supplier.label}
                                      </Option>
                                  ))}
                              </Select>
                          </div>
                      </Col>
                  </Row>
              </> */}
        <Row className="mt-3">
          <Col>
            <div
              // style={{ width: screenSize - 320, overflow: "auto" }}
              id="customTable"
            >
              <Table
                // rowSelection={rowSelection}
                columns={columnsLines}
                dataSource={qcLines}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30", "50"],
                }}
                bordered
                size="middle"
                scroll={{ x: 1400 }}
                rowKey="id"
              />
            </div>
          </Col>
        </Row>
      </Drawer>
    </>
  );


};

export default QCList;
