import makeApiCall from "../master/axiosIndex";

const paymentApis = {
  submitPayment: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/submitPayment",
      method: "post",
      data,
    }),
  runPayment: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/runPayment",
      method: "post",
      data,
    }),
  fetchAllPendingApprovalPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingApprovalPayments",
      method: "get",
    }),
  fetchPayment: (paymentNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/fetchPayment?paymentNumber=${paymentNumber}`,
      method: "get",
    }),
  approvePayment: (paymentNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/approvePayment?paymentNumber=${paymentNumber}`,
      method: "patch",
    }),
  disapprovePayment: (paymentNumber: string, reason: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/disapprovePayment?paymentNumber=${paymentNumber}&rejectedReason=${reason}`,
      method: "patch",
    }),
  fetchAllPendingRunPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingRunPayments",
      method: "get",
    }),
  fetchAllPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPayments",
      method: "get",
    }),
  fetchAllInvoicePaymentLines: (invoiceNumber: any): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/fetchAllInvoicePaymentLines?invoiceNumber=${invoiceNumber}`,
      method: "get",
    }),
  fetchAllOrgUnUtilizedCreditDebitNotes: (supplierId: any): Promise<any> =>
    makeApiCall<any>({
      url: `/credit-debit-note/fetchAllOrgUnUtilizedCreditDebitNotes?supplierId=${supplierId}`,
      method: "get",
    }),
  fetchPayableInvoicesForPaymentsWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchPayableInvoicesForPaymentsWithPagination",
      method: "get",
      params,
    }),
  fetchUserPendingApprovalPaymentsWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchUserPendingApprovalPaymentsWithPagination",
      method: "get",
      params,
    }),
  fetchAllPendingRunPaymentsWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingRunPaymentsWithPagination",
      method: "get",
      params,
    }),
  fetchUniqueSuppliersOfPayableInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchUniqueSuppliersOfPayableInvoices",
      method: "get",
    }),
  fetchPayableInvoicesWidgetCounts: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchPayableInvoicesWidgetCounts",
      method: "get",
    }),
  fetchAllPaidInvoicesWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllPaidInvoicesWithPagination",
      method: "get",
      params,
    }),
  fetchStatusFilterOptions: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/purchase-request/status`,
    }),
};

export default paymentApis;
