import makeApiCall from "./axiosIndex";
const budgetApis = {
  budgetCreationMethod: (data: any) =>
    makeApiCall({
      url: "/budget/createBudget",
      method: "post",
      data,
    }),
  createNewVersionBudget: (data: any) =>
    makeApiCall({
      url: "/budget/createNewVersionBudget",
      method: "post",
      data,
    }),
  getBudgetBySite: (siteId: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/budget/getbySite`,
      params: { site: siteId },
    }),
  fetchBudgetAll: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/budget/getAll",
    }),
  fetchBudgetById: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/budget/id/${id}`,
    }),
  pendingApprovals: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/budget/pendingApprovals`,
      // params: { status }
    }),
  approveBudget: (headerId: any): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/budget/approveBudget`,
      params: { headerId },
    }),
  rejectBudget: (headerId: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/budget/rejectBudget`,
      data,
      params: { headerId },
    }),
  budgetSpendAnalysis: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/budget-dashboard/budgetSpendAnalysis",
      method: "post",
      data: data,
    }),
  materialWiseSpendAnalysis: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/budget-dashboard/materialWiseSpendAnalysis",
      method: "post",
      data: data,
    }),
  materialWiseBudgetOverRun: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/budget-dashboard/materialWiseBudgetOverRun",
      method: "post",
      data: data,
    }),
  holdBudget: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/budget/holdBudget`,
      data,
    }),
  completeBudget: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/budget/completeBudget`,
      data,
    }),
  inactivateBudget: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/budget/inactivateBudget`,
      data,
    }),
  fetchBudgetListPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/budget/paginationList",
      method: "get",
      params,
    }),
  fetchBudgetPaginationPendingApprovals: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/budget/paginationPendingApprovals",
      method: "get",
      params,
    }),
  fetchStatusFilterOptions: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/budget/status`,
    }),
};

export default budgetApis;
