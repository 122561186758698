import {
  DatePicker,
  Empty,
  Flex,
  Form,
  Progress,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import purchaseOrderCreation from "../../api/purchase-orders/pruchaseOrderCreation";
import SearchInput from "../form-components/SearchComponent";
import { SearchOutlined } from "@ant-design/icons";
import invoiceApis from "../../api/Invoice/invoice";
import dispatchApis from "../../api/dispatch/dispatch";
import budgetApis from "../../api/master/budget";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import quotationApis from "../../api/master/quotations";
import supplierApis from "../../api/master/supplier";
import usersApis from "../../api/master/users";
import materialsApis from "../../api/master/materials";
import Export from "../../components/export/Export";
import paymentApis from "../../api/payments/payments";
import dayjs from "dayjs";
import misc from "../../api/misc/dropDowns";
import miscService from "../../api/misc/misc";
import { InfoCircleOutlined } from "@ant-design/icons";
import creditDebitNoteApis from "../../api/credit-debit-note/credit-debit-note";

const { RangePicker } = DatePicker;
type WidgetType = {
  title: string;
  key: string;
  color: string;
  percentage: number;
  value: number;
};
type SelectOptions = {
  label: string;
  value: string;
};
type SelectFilterType = {
  title: string;
  placeholder?: string;
  options: SelectOptions[];
  onChange?: (value: any) => void;
};
const TransactionFlowDefaultTemplate: React.FC<any> = ({
  title,
  moduleKey,
  widget,
  columns,
  sideNavigation,
  selectFilters,
  previousPath,
  extra,
  topRightJSX,
  refresh,
  components,
  rowSelection,
  form,
}: {
  title: string;
  moduleKey: string;
  columns: any[];
  sideNavigation?: any;
  widget?: {
    isActive: boolean;
    list: WidgetType[];
  };
  selectFilters?: SelectFilterType[];
  previousPath?: string;
  topRightJSX?: any;
  extra?: {
    id?: any;
    tableScrollX?: number;
    dateRange?: boolean;
    excelExport?: boolean;
    dataFilterTootip?: string;
  };
  refresh?: boolean;
  components?: any;
  rowSelection?: any;
  form?: any;
}) => {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const [customDateRange, setCustomDateRange] = useState<any>();
  const [dataSource, setDataSource] = useState<any>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "40", "50"],

    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [screenSize, setScreenSize] = useState(0);

  const [searchQuery, setsearchQuery] = useState(null);

  const selectFilter1 = watch("selectFilter1");
  const selectFilter2 = watch("selectFilter2");
  const selectFilter3 = watch("selectFilter3");
  const dateRange = watch("dateRange");
  //---------------------------------------- Functions Start ----------------------------------------

  const fetchData = async (params: any) => {
    setLoading(true);
    try {
      const {
        current,
        sortOrder,
        pageSize,
        direction,
        pageDiff,
        paginationId,
        searchQuery,
        selectFilter1,
        selectFilter2,
        selectFilter3,
        dateRange,
        customDateRange,
      } = params;
      console.log("dateRange", dateRange);
      if (
        extra?.dateRange &&
        ((dateRange == "Custom Date" && customDateRange == undefined) ||
          dateRange == undefined)
      )
        return;
      const { from, to }: any =
        dateRange !== undefined && convertToDates(dateRange);
      console.log("from, to", from, to);
      let apiResponse: any = {};
      switch (moduleKey) {
        case "excelexport-requested-list":
          apiResponse =
            await miscService.fetchExcelExportRequestedListWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
            });
          break;
        case "view-purchase-order":
          apiResponse =
            await purchaseOrderCreation.fetchAllOrgOrdersWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
              orderStatus: selectFilter1,
              startDate: from,
              endDate: to,
            });
          break;
        case "approvals-purchase-order":
          apiResponse =
            await purchaseOrderCreation.fetchUserPendingApprovalOrdersWithPagination(
              {
                sortOrder,
                pageSize,
                direction,
                pageDiff,
                paginationId,
                searchQuery,
              }
            );
          break;
        case "view-invoice-list":
          apiResponse = await invoiceApis.fetchAllInvoicesWithPagination({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
            invoiceStatus: selectFilter1,
            startDate: from,
            endDate: to,
          });
          break;
        case "all-credit-debit-notes":
          apiResponse =
            await creditDebitNoteApis.fetchAllCreditDebitNotesWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
            });
          break;
        case "pending-approval-credit-debit-notes":
          apiResponse =
            await creditDebitNoteApis.fetchAllUserPendingConfirmationCreditDebitNotesWithPagination(
              {
                sortOrder,
                pageSize,
                direction,
                pageDiff,
                paginationId,
                searchQuery,
              }
            );
          break;
        case "view-dispatch-list":
          apiResponse =
            await dispatchApis.fetchAllGrnPendingDispatchesWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
              startDate: from,
              endDate: to,
            });
          break;
        case "pending-approvals-grn-list":
          apiResponse =
            await dispatchApis.fetchAllUserPendingApprovalGrnsWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
            });
          break;
        case "delivered-dispatch-list":
          apiResponse =
            await dispatchApis.fetchAllDeliveredDispatchesWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
              startDate: from,
              endDate: to,
            });
          break;
        case "view-budget-list":
          apiResponse = await budgetApis.fetchBudgetListPagination({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
            status: selectFilter1,
            startDate: from,
            endDate: to,
          });
          break;
        case "pendingApproval-budget":
          apiResponse = await budgetApis.fetchBudgetPaginationPendingApprovals({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
          });
          break;
        case "view-purchase-requests-list":
          apiResponse = await purchaseRequestApis.fetchPrListPagination({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
            status: selectFilter1,
            startDate: from,
            endDate: to,
          });
          break;
        case "approvals-purchase-request":
          apiResponse =
            await purchaseRequestApis.fetchPrPaginationPendingApprovals({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
            });
          break;
        case "view-rfqs":
          apiResponse = await quotationApis.fetchRFQPaginationList({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
            overallStatus: selectFilter1,
            startDate: from,
            endDate: to,
          });
          break;
        case "rfq-approvals":
          apiResponse = await quotationApis.fetchRFQPaginationPendingApprovals({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
          });
          break;
        case "pending-payment-approvals":
          apiResponse =
            await paymentApis.fetchUserPendingApprovalPaymentsWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
            });
          break;
        case "run-payments":
          apiResponse =
            await paymentApis.fetchAllPendingRunPaymentsWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
            });
          break;
        case "view-payments":
          apiResponse = await paymentApis.fetchAllPaidInvoicesWithPagination({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
          });
          break;
        case "invoice-payables":
          apiResponse =
            await paymentApis.fetchPayableInvoicesForPaymentsWithPagination({
              sortOrder,
              pageSize,
              direction,
              pageDiff,
              paginationId,
              searchQuery,
              supplierFilters: selectFilter1,
            });
          // for editable rows key is added (mandatory)
          const { data, status } = apiResponse;
          if (status) {
            apiResponse.data = {
              ...data,
              list: data?.list?.map((row: any) => {
                return {
                  ...row,
                  key: row?.invoiceNumber,
                };
              }),
            };
          }
          break;
        case "suppliers-list":
          apiResponse = await supplierApis.fetchSupplierWithPagenation({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
            categoryFilters: selectFilter1,
          });
          break;
        case "users-list":
          apiResponse = await usersApis.fetchBuyerUserWithPagenation({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
          });
          break;
        case "material-list":
          apiResponse = await materialsApis.fetchProductWithPagenation({
            sortOrder,
            pageSize,
            direction,
            pageDiff,
            paginationId,
            searchQuery,
            categoryFilters: selectFilter1,
          });
          break;
        case "qc-list":
          apiResponse = await quotationApis.fetchQCPagination({
            sortOrder,
            pageSize,
            direction: "P",
            pageDiff: current,
            paginationId,
            searchQuery,
          });
          break;
        case "qc-approvals":
          apiResponse =
            await quotationApis.fetchQCApprovalsPaginationPendingApprovals({
              sortOrder,
              pageSize,
              direction: "P",
              pageDiff: current,
              paginationId,
              searchQuery,
            });
          break;
        case "ready-to-award":
          apiResponse =
            await quotationApis.fetchReadyToRewardPaginationPendingApprovals({
              sortOrder,
              pageSize,
              direction: "P",
              pageDiff: current,
              paginationId,
              searchQuery,
            });
          break;
        default:
          alert("Invalid key " + moduleKey);
      }
      const { data, status } = apiResponse;
      if (status) {
        const { list, count } = data;
        setDataSource(list);
        setPagination((prev) => ({
          ...prev,
          total: Number(count),
        }));
      }
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  const convertToDates = (dateType: string) => {
    try {
      switch (dateType) {
        case "Month to Date":
          return {
            from: dayjs().startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Quarter to Date":
          return {
            from: dayjs()
              .subtract(3, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Year to Date":
          return {
            from: dayjs().startOf("year").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        default:
          if (!customDateRange)
            return {
              from: dayjs()?.format("YYYY-MM-DD"),
              to: dayjs()?.format("YYYY-MM-DD"),
            };
          return {
            from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
            to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
          };
      }
    } catch (error) {
      return {
        from: dayjs()?.format("YYYY-MM-DD"),
        to: dayjs()?.format("YYYY-MM-DD"),
      };
    }
  };

  const tableOnChange = async (
    newPagination: any,
    filters: any,
    sorter: any
  ) => {
    const apiParams = {
      oldPage: pagination.current,
      current: newPagination.current?.toString(),
      sortOrder: "DESC",
      pageSize: pagination.pageSize,
      direction: "P",
      pageDiff: 0,
      paginationId: null,
      searchQuery,
    };

    if (sorter.order) {
      apiParams.sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
    } else if (newPagination.pageSize != pagination.pageSize) {
      // if page size is changed
      apiParams.pageSize = newPagination.pageSize;
      const _currentPage =
        Math.floor(
          ((Number(pagination.current) - 1) * Number(pagination.pageSize)) /
            Number(newPagination.pageSize)
        ) + 1; // calculating new current page based on new page size
      apiParams.current = _currentPage;
      apiParams.pageDiff = _currentPage - 1;
    } else {
      if (newPagination.current != pagination.current) {
        apiParams.direction =
          newPagination.current > pagination.current
            ? "F" // Forward
            : "B"; // Backward
        apiParams.paginationId =
          apiParams.direction == "F"
            ? dataSource[dataSource.length - 1]?.paginationId // Last item ID for backward direction
            : dataSource[0]?.paginationId; // First item ID for forward direction

        apiParams.pageDiff =
          Math.abs(newPagination.current - pagination.current) - 1;
      }
    }

    setPagination({
      ...pagination,
      current: apiParams.current,
      pageSize: apiParams.pageSize,
    });
    await fetchData({
      ...apiParams,
      selectFilter1,
      selectFilter2,
      selectFilter3,
      dateRange,
      customDateRange,
    });
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
  };

  //---------------------------------------- Functions End----------------------------------------

  const refreshData = () => {
    console.log("refreshData");
    fetchData({
      oldPage: 1,
      current: pagination.current.toString(),
      sortOrder: "DESC",
      pageSize: pagination.pageSize.toString(),
      direction: "P",
      pageDiff: pagination.current - 1,
      paginationId: null,
      searchQuery,
      dateRange,
      customDateRange,
    });
  };
  useEffect(() => {
    if (extra?.dateRange) {
      setValue("dateRange", "Quarter to Date");
    }
    refreshData();
  }, [refresh]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (
      selectFilter1 !== undefined ||
      selectFilter2 !== undefined ||
      selectFilter3 !== undefined ||
      dateRange !== undefined ||
      customDateRange !== undefined
    ) {
      setPagination({
        ...pagination,
        current: 1,
      });
      fetchData({
        oldPage: 1,
        current: pagination.current.toString(),
        sortOrder: "DESC",
        pageSize: pagination.pageSize.toString(),
        direction: "P",
        // pageDiff: pagination.current - 1,
        pageDiff: 0,
        paginationId: null,
        searchQuery,
        selectFilter1,
        selectFilter2,
        selectFilter3,
        dateRange,
        customDateRange,
      });
    }
  }, [selectFilter1, selectFilter2, selectFilter3, dateRange, customDateRange]);

  return (
    <>
      <Head title={title} />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {sideNavigation}
            <div className="card-inner card-inner-lg">
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent className="w-100">
                    <BlockTitle
                      tag="h4"
                      className="d-flex justify-content-between align-items-center  w-100"
                    >
                      <span>{title}</span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <Button
                        className={`btn-icon btn-trigger toggle-expand me-n1`}
                      >
                        <Icon name="menu-alt-r"></Icon>
                      </Button>
                      <div className="toggle-expand-content">
                        <ul className="nk-block-tools g-3">
                          {extra?.excelExport && (
                            <li>
                              <Export
                                data={{
                                  sortOrder: "DESC",
                                  pageSize: 100,
                                  direction: "P",
                                  pageDiff: 0,
                                  searchQuery,
                                  selectFilter1,
                                  selectFilter2,
                                  selectFilter3,
                                  dateRange:
                                    dateRange && convertToDates(dateRange),
                                  customDateRange,
                                }}
                                moduleKey={moduleKey}
                              />
                            </li>
                          )}
                          <li className="nk-block-tools-opt">{topRightJSX}</li>
                        </ul>
                      </div>
                    </div>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                {widget?.isActive && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {widget?.list.map((widget, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid #dbdfea",
                          padding: "15px",
                          borderRadius: "8px",
                          width: "calc(20% - 10px)", // Ensure widgets are 20% of the width
                          minWidth: "150px", // Ensure a minimum width for smaller screens
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ marginBottom: "20px" }}>
                          <h6 style={{ margin: 0 }}>{widget.title}</h6>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Progress
                              type="circle"
                              percent={
                                Number(widget?.percentage)
                                  ?.toFixed(2)
                                  ?.toString() as any
                              }
                              width={50}
                              strokeColor={widget.color}
                              format={(percent) => `${percent}%`}
                            />
                          </div>
                          <div style={{ flex: 1, textAlign: "center" }}>
                            <p style={{ fontSize: "24px", margin: 0 }}>
                              {widget.value}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Block>
              <FormProvider {...methods}>
                <form className="w-100 d-flex align-items-center justify-content-start">
                  {(selectFilters && selectFilters?.length > 0) ||
                  extra?.dateRange ? (
                    <div className="d-flex align-items-start flex-wrap w-75 mb-2">
                      {/* {selectFilters && selectFilters?.length > 0 || extra?.dateRange && ( */}
                      <div className="d-flex align-items-center flex-wrap w-100">
                        {selectFilters?.map(
                          (filter: SelectFilterType, index: any) => {
                            return (
                              <div
                                className="mt-1 mb-0 w-25"
                                style={{ marginRight: 10 }}
                              >
                                <FormSelect
                                  name={`selectFilter${index + 1}`}
                                  label={filter?.title}
                                  // required={false}
                                  hideRequiredMark={true}
                                  placeholder={
                                    filter?.placeholder || filter?.title
                                  }
                                  options={filter?.options}
                                  onChange={(e: any) => {
                                    if (typeof filter.onChange === "function") {
                                      filter.onChange(e);
                                    }
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                        {extra?.dateRange && (
                          <div className="w-50" style={{ display: "contents" }}>
                            <div
                              className="mt-1 mb-0 w-25"
                              style={{ marginRight: 10 }}
                            >
                              <FormSelect
                                name="dateRange"
                                required={true}
                                hideRequiredMark={true}
                                defaultValue={"Quarter to Date"}
                                // label=""
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Select Date Range
                                    <Tooltip title={extra?.dataFilterTootip}>
                                      <InfoCircleOutlined
                                        style={{
                                          marginLeft: 8,
                                          fontSize: 12,
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                }
                                // optionLabel="Date Range"
                                // placeholder="Select Date Range"
                                options={[
                                  {
                                    value: "Month to Date",
                                    label: "Month to Date",
                                  },
                                  {
                                    value: "Quarter to Date",
                                    label: "Quarter to Date",
                                  },
                                  {
                                    value: "Year to Date",
                                    label: "Year to Date",
                                  },
                                  {
                                    value: "Custom Date",
                                    label: "Custom Date",
                                  },
                                ]}
                              />
                            </div>
                            <div className="mt-5 mb-0">
                              {dateRange === "Custom Date" && (
                                <RangePicker
                                  value={
                                    customDateRange
                                      ? [
                                          dayjs(customDateRange[0].toDate()),
                                          dayjs(customDateRange[1].toDate()),
                                        ]
                                      : null
                                  }
                                  onChange={(dates) => {
                                    if (dates && dates[0] && dates[1]) {
                                      setCustomDateRange([dates[0], dates[1]]);
                                    } else {
                                      setCustomDateRange(null);
                                    }
                                  }}
                                  style={{ width: "100%" }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* // )} */}
                    </div>
                  ) : null}

                  <div
                    className={`w-${
                      (selectFilters && selectFilters?.length > 0) ||
                      extra?.dateRange
                        ? "25"
                        : "100"
                    } mb-2 `}
                  >
                    <SearchInput
                      name="search"
                      placeholder="Search..."
                      width="100%"
                      prefixIcon={<SearchOutlined />}
                      onDebouncedSearch={async (value: any) => {
                        setsearchQuery(value);
                        setPagination({
                          ...pagination,
                          current: 1,
                        });
                        await fetchData({
                          oldPage: 1,
                          current: 1,
                          sortOrder: "DESC",
                          pageSize: pagination.pageSize.toString(),
                          direction: "P",
                          pageDiff: 0,
                          paginationId: null,
                          searchQuery: value,
                          selectFilter1,
                          selectFilter2,
                          selectFilter3,
                          dateRange,
                          customDateRange,
                        });
                      }}
                      debounceDelay={300} // Adjust delay as needed
                    />
                  </div>
                </form>
              </FormProvider>
              <div className="content clearfix">
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : dataSource?.length > 0 ? (
                  <div
                    style={{
                      width: screenSize - (sideNavigation ? 330 : 100) + "px",
                      margin: "0px auto",
                    }}
                  >
                    <Form form={form} component={false}>
                      <Table
                        className="customTable"
                        columns={columns}
                        rowSelection={rowSelection}
                        dataSource={dataSource}
                        pagination={pagination}
                        onChange={tableOnChange}
                        components={components}
                        bordered
                        size="middle"
                        scroll={{
                          x: extra?.tableScrollX || 1500,
                        }}
                      />
                    </Form>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Data Found " />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default TransactionFlowDefaultTemplate;
