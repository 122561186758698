import React, { useState, useEffect, FC, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import {
  Progress,
  Table,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Drawer,
  Empty,
  Flex,
  Spin,
} from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import quotationApis from "../../api/master/quotations";
import Swal from "sweetalert2";
import moment from "moment";
import UserLabel from "../../utils/UserLabel";
import { formatCurrency, colors } from "../../utils/Utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface TableData {
  key: number;
  rfqId: string;
  qcId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
  qcStatus: string;
  initialPrice: string;
  price: string;
  tax: string;
}
const QCApprovals: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [screenSize, setScreenSize] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const [tableData, setTableData] = useState<TableData[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectRecord, setRejectRecord] = useState<any>(null);

  const [qcLines, setQCLines] = useState<any>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const navigate = useNavigate();

  const [navigationQuotationId, setNavigationQuotationId] = useState<any>();
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [form] = Form.useForm();

  const supplierColorMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    let colorIndex = 0;

    qcLines.forEach((record: any) => {
      if (!map[record.supplierName]) {
        map[record.supplierName] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });

    return map;
  }, [qcLines]);

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 200,
      align: "left",
      // render: (text, record) => (
      //   <a onClick={() => handleRFQClick(record)}>{text}</a>
      // ),
    },
    {
      title: "QC ID",
      dataIndex: "qcId",
      key: "qcId",
      fixed: "left",
      width: 100,
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleQcClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      // fixed: "left",
      width: 250,
      align: "left",
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
      align: "left",
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   // fixed: "right",
    //   render: (text, record) => (
    //     <span style={{ color: record.statusColor }}>{text}</span>
    //   ),
    //   width: 150,
    //   sorter: (a, b) => a.status.localeCompare(b.status),
    //   align: "left",
    // },
  ];

  const columnsLines: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 300,
      render: (text: string, record: any, index: number) => (
        <UserLabel
          supplierName={record.supplierName}
          color={supplierColorMap[record.supplierName]}
          text={text}
        />
      ),
    },
    {
      title: "Material Name",
      fixed: "left",
      dataIndex: "materialName",
      width: 160,
      key: "materialName",
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      width: 160,
      key: "uomName",
    },
    {
      title: "Asked Quantity",
      dataIndex: "askedQty",
      width: 160,
      key: "askedQty",
    },
    {
      title: "Awarded Quantity",
      dataIndex: "awardedQty",
      key: "awardedQty",
      width: 160,
    },
    {
      title: "Awarded Qty %",
      dataIndex: "awardedPercentage",
      key: "awardedPercentage",
      width: 160,
      render: (text: any) => `${text.toFixed(2)}%`,
    },
    {
      title: "Initial Quotation",
      dataIndex: "initialPrice",
      key: "initialPrice",
      width: 160,
      render: (text: any, record: any) => <>{formatCurrency(text, "INR")}</>,
    },
    {
      title: "Best Quotation",
      dataIndex: "price",
      key: "price",
      width: 160,
      render: (text: any, record: any) => <>{formatCurrency(text, "INR")}</>,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      width: 160,
      render: (text: any, record: any) => (
        <>
          {formatCurrency(text, "INR")}
          <Popover
            content={
              <div>
                <p>
                  CGST:{" "}
                  {record.cgst == 0 ? "N/A" : record?.cgst + `${"%"}` || "N/A"}
                </p>
                <p>
                  SGST:{" "}
                  {record.sgst == 0 ? "N/A" : record?.sgst + `${"%"}` || "N/A"}
                </p>
                <p>
                  IGST:{" "}
                  {record.igst == 0 ? "N/A" : record.igst`${"%"}` || "N/A"}
                </p>
              </div>
            }
            title="Tax Breakdown"
          >
            <InfoCircleOutlined style={{ marginLeft: 8 }} />
          </Popover>
        </>
      ),
    },
    {
      title: "Line Total",
      dataIndex: "lineTotal",
      key: "lineTotal",
      width: 160,
      render: (text: any, record: any) => <>{formatCurrency(text, "INR")}</>,
    },
    {
      title: "Status",
      dataIndex: "qcStatus",
      key: "qcStatus",
      width: 160,
      // fixed: "right",
      // render: (text:any, record:any) => (
      //   <span style={{ color: record.statusColor }}>{text}</span>
      // ),
      // width: 150,
      // sorter: (a:any, b:any) => a.qcStatus.localeCompare(b.qcStatus),
      // align: "left",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 160, // Increase width to accommodate additional buttons
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color="success"
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Approve it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleApprove(record);
                }
              });
              // Handle approve action here
            }}
            className="mx-1" // Adds a small margin between buttons
          >
            <Icon name="check" title="Approve" />
          </Button>

          <Button
            size="sm"
            color="danger"
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Reject it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleReject(record);
                } else {
                }
              });
              // Handle reject action here
            }}
          >
            <Icon name="cross" tile="Reject" />
          </Button>
        </>
      ),
    },
  ];

  const handleQcClick = async (record: any) => {
    console.log("record", record);
    setNavigationQuotationId(record);
    try {
      const res = await quotationApis.getByQCId(record.qcId);
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        setQCLines(response.data);
        setDrawerVisible(true);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleReject = async (record: string) => {
    setRejectRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Assuming `record.headerId` is used to identify the purchase order
      const payload = {
        //     headerId: rejectRecord,
        //     reason: values.reason,
        // };
        quotationId: rejectRecord.quotationId,
        shortListId: rejectRecord.shortListId,
        reason: values.reason,
        id: rejectRecord.id,
      };

      // Call the API to submit the rejection
      await apiCallToRejectBudget(payload);

      // Handle success (e.g., show a success message, refresh the table)

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      // Handle validation errors or API errors
    }
  };

  const apiCallToRejectBudget = async (payload: any) => {
    try {
      const res = await quotationApis.qcReject(payload);
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire(
          "Rejected!",
          navigationQuotationId.qcId + " has been Rejected",
          "success"
        );
        setDrawerVisible(false);
        handleQcClick(navigationQuotationId);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    } finally {
      setRefreshToggle(!refreshToggle);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleApprove = async (record: any) => {
    let data = {
      quotationId: record.quotationId,
      shortListId: record.shortListId,
      reason: "",
      id: record.id,
    };
    try {
      const res = await quotationApis.qcApprove(data);
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire(
          "Approved!",
          navigationQuotationId.qcId + " has been Approved",
          "success"
        );
        // fetchPendingApprovalList();
        console.log("navigationQuotationId", navigationQuotationId);
        handleQcClick(navigationQuotationId);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setRefreshToggle(!refreshToggle);
  };

  const QCNavigation = () => {
    console.log("navigationQuotationId", navigationQuotationId);
    let rfq = navigationQuotationId;
    navigate("/rfq-full-view", { state: { rfq, from: "qc" } });
  };

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="qc-approvals"
        title="Pending QC Approvals"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <QuotationsAside updateSm={setSm} sm={sm} />
          </div>
        }
        extra={{
          tableScrollX: 1100,
        }}
        refresh={refreshToggle}
      />

      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>QC Lines</strong>
            <div style={{ textAlign: "right" }}>
              <Button
                color="primary"
                className="m-3 mt-0 mb-0"
                onClick={() => {
                  QCNavigation();
                }}
              >
                Quotation Comparison
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        width="90vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        <Row>
          <Col>
            <div
              // style={{ width: screenSize - 320, overflow: "auto" }}
              id="customTable"
            >
              <Table
                columns={columnsLines}
                dataSource={qcLines}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30", "50"],
                }}
                bordered
                size="middle"
                scroll={{ x: 1400 }}
              />
            </div>
          </Col>
        </Row>

        <AntModal
          title="Reject QC"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical" name="rejectForm">
            <Form.Item
              name="reason"
              label="Reason for Rejection"
              rules={[{ required: true, message: "Please input your reason!" }]}
            >
              <Input.TextArea placeholder="Type your reason here..." />
            </Form.Item>
          </Form>
        </AntModal>
      </Drawer>
    </>
  );
};

export default QCApprovals;
