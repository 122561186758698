import React from "react";
import ReportTemplate2 from "./reportTemplate2";
import { useLocation, useNavigate, useParams } from "react-router";

function MaterialConsumptionDetailReport() {
  const location = useLocation();
  const materialName = location.state?.materialName;
  const categoryId = location.state?.categoryId;
  const materialId = location.state?.materialId;
  const siteId = location.state?.siteId;
  const columns: any = [
    {
      title: "Site Name",
      key: "siteName",
      dataIndex: "siteName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 150,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 150,
    },
    {
      title: "BOQ QTY",
      key: "boqQty",
      dataIndex: "boqQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Issue QTY",
      key: "issueQyt",
      dataIndex: "issueQyt",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Utilized QTY",
      key: "utilizedQty",
      dataIndex: "utilizedQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
  ];
  return (
    <ReportTemplate2
      pageTitle={`${materialName} - Material Consumption Summary, Detail Report`}
      moduleKey="materialUtilizationSummaryDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        category: false,
        material: false,
      }}
      extra={{
        // tableScrollX: 1700,
        customData: {
          siteId,
          materialId,
          categoryId,
        },
      }}
      previousPath="/material-consumption-report-list"
    />
  );
}

export default MaterialConsumptionDetailReport;
