import React from "react";
import ReportTemplate2 from "../reportTemplate2";

function MaterialDamageSummary() {
  const columns: any = [
    {
      title: "Site Name",
      key: "siteName",
      dataIndex: "siteName",
      width: 150,
    },
    {
      title: "Block Name",
      key: "blockName",
      dataIndex: "blockName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 150,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 150,
    },
    {
      title: "Issue QTY",
      key: "issueQyt",
      dataIndex: "issueQyt",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "Damaged QTY",
      key: "damagedQty",
      dataIndex: "damagedQty",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)}</span>;
      },
    },
    {
      title: "% to Issued QTY",
      key: "damagedQtyPercentage",
      dataIndex: "damagedQtyPercentage",
      width: 100,
      render: (text: string) => {
        const value = Number(text); // Convert text to a number
        if (isNaN(value)) {
          return <span style={{ textAlign: "right" }}>N/A</span>; // Handle invalid number
        }
        return <span style={{ textAlign: "right" }}>{value.toFixed(2)} %</span>;
      },
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Material Damage Summary"
      moduleKey={window.location.pathname}
      columns={columns}
      filtersOption={{
        site: true,
        category: true,
        material: true,
      }}
      previousPath="/view-material-management-reports"
    />
  );
}

export default MaterialDamageSummary;
