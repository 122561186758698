// store/highlightSlice.ts
import { createSlice } from "@reduxjs/toolkit";

const highlightSlice = createSlice({
  name: "highlight",
  initialState: {
    downloadHighlighted: false,
  },
  reducers: {
    setHighlight: (state, action) => {
      state.downloadHighlighted = action.payload;
    },
  },
});

export const { setHighlight } = highlightSlice.actions;

export default highlightSlice.reducer;
