import makeApiCall from "../master/axiosIndex";

const dispatchApis = {
  submitDispatch: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/dispatch/submitDispatch",
      method: "post",
      data: data,
    }),
  fetchAllDispatches: (): Promise<any> =>
    makeApiCall<any>({
      url: "/dispatch/fetchAllUserGrnPendingDispatches",
      method: "get",
    }),
  fetchDispatch: (id: string): Promise<any> =>
    makeApiCall<any>({
      url: `/dispatch/fetchDispatch?dispatchNumber=${id}`,
      method: "get",
    }),
  fetchAllDispatchesByInvoiceLineNo: (invoiceLineNo: any): Promise<any> =>
    makeApiCall<any>({
      url: `/dispatch/fetchAllDispatchesByInvoiceLineNo?invoiceLineNo=${invoiceLineNo}`,
      method: "get",
    }),
  fetchAllUserDeliveredDispatches: (): Promise<any> =>
    makeApiCall<any>({
      url: "/dispatch/fetchAllUserDeliveredDispatches",
      method: "get",
    }),
  acceptGrn: (dispatchNumber: string, grnId: any, data: any): Promise<any> =>
    makeApiCall<any>({
      url: `/dispatch/acceptGrn/${dispatchNumber}/${encodeURIComponent(grnId)}`,
      method: "put",
      data: data,
    }),
  sendGrnEmailNotification: (dispatchNumber: any, data: any): Promise<any> =>
    makeApiCall<any>({
      url: `/dispatch/sendGrnEmailNotification/${dispatchNumber}`,
      method: "post",
      data: data,
    }),
  fetchAllGrnPendingDispatchesWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/dispatch/fetchAllGrnPendingDispatchesWithPagination",
      method: "get",
      params,
    }),
  fetchAllDeliveredDispatchesWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/dispatch/fetchAllDeliveredDispatchesWithPagination",
      method: "get",
      params,
    }),
    fetchAllUserPendingApprovalGrnsWithPagination: (params: any): Promise<any> =>
      makeApiCall<any>({
        url: "/dispatch/fetchAllUserPendingApprovalGrnsWithPagination",
        method: "get",
        params,
      }),
};

export default dispatchApis;
