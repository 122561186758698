import makeApiCall from "./axiosIndex";

const supplierApis = {
  fetchSupplier: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchSupplier" }),
  deleteSupplierById: (supplierId: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteSupplierById`,
      params: { id: supplierId },
    }),
  createSupplier: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createSupplier",
      data,
    }),
  updateSupplierById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateSupplierById`,
      params: { id: id },
      data,
    }),
  fetchSupplierById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchSupplierById`,
      params: { id },
    }),
  createSupplierCategory: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createSupplierCategory",
      data,
    }),
  updateSupplierCategoryById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateSupplierCategoryById`,
      params: { id },
      data,
    }),
  fetchSupplierCategoryById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchSupplierCategoryById`,
      params: { id },
    }),
  fetchSuppliersByCategoryId: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/buyer/fetchSuppliersByCategoryId`,
      data: id,
    }),
  deleteSupplierCategoryById: (supplierCategoryID: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteSupplierCategoryById`,
      params: { id: supplierCategoryID },
    }),
  fetchSupplierCategory: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchSupplierCategory" }),
  fetchIndustryType: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/misc/allIndustryTypes" }),
  getContructionsMaterialTypes: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/constructionMaterials`,
    }),
  fetchMaterialTyepesByCategory: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchMaterialTyepesByCategory`,
    }),
  fetchSupplierFilter: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchSupplierFilter",
    }),
  verifyLegalDetails: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/verifyLegalDetails",
      data,
    }),
  fetchCategoryProducts: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchCategoryProducts",
      params: {
        categoryId: data,
      },
    }),
  fetchSupplierWithPagenation: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/buyer/fetchSupplierWithPagenation",
      method: "get",
      params,
    }),
  fetchCategoryFilteredInSupplier: (): Promise<any> =>
    makeApiCall<any>({
      url: "/buyer/fetchCategoryFilteredInSupplier",
      method: "get",
    }),
};

export default supplierApis;
